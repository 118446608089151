<template>
  <main class="queue">
    <mks-loading v-if="loading.queue === 'PENDING'"></mks-loading>
    <template v-else>
      <header class="queue__header">
        <h1>> {{ content[getLang].title }}</h1>
      </header>
      <div class="queue__main">
        <div class="queue__menu">
          <Timeline
            :items="emailsTimeline"
            @select="(id) => (selected = id)"
          ></Timeline>
        </div>
        <div class="queue__workspace" v-if="selected">
          <div class="editor">
            <div class="editor__header">
              <div class="left">
                <h2 class="editor__subject">{{ emails[selected].subject }}</h2>
                <p class="editor__date">
                  {{ $date(emails[selected].date).format("dddd DD MMMM") }}
                </p>
              </div>
              <div class="right editor__status">
                <div class v-if="emails[selected].recipients > 0">
                  <mks-tag
                    :label="
                      emails[selected].recipients +
                        ' ' +
                        content[getLang].recipients
                    "
                  ></mks-tag>
                </div>
                <div class v-if="emails[selected].sent">
                  <mks-tag
                    :label="content[getLang].sent"
                    color="positive"
                  ></mks-tag>
                </div>
              </div>
            </div>
            <div class="editor__preview-container">
              <p class="editor__preview">{{ content[getLang].emailPreview }}</p>
              <iframe ref="iframe"></iframe>
            </div>
            <div>
              <div
                class="email__fieldgroup"
                v-for="(field, index) in form.email"
                :key="index"
              >
                <mks-field-label>{{ field.name[getLang] }}</mks-field-label>
                <mks-field
                  class="fieldgroup__field"
                  :name="index"
                  :id="index"
                  :type="field.type"
                  :placeholder="
                    field.placeholder ? field.placeholder[getLang] : null
                  "
                  :description="
                    field.description ? field.description[getLang] : null
                  "
                  :options="field.options"
                  :min="field.min"
                  :max="field.max"
                  v-model="emails[selected][index]"
                  :editorToolbar="field.editorToolbar"
                />
              </div>
              <div class="edition__actions">
                <mks-button
                  tag="button"
                  type="positive"
                  :loading="loading.save === 'PENDING'"
                  @click="save(selected)"
                  >{{ content[getLang].save }}</mks-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </main>
</template>
<script>
import Timeline from "./components/timeline.vue";
import lang from "../../mixins/lang.js";
import { functions as firebaseFunctions } from "@/modules/firebase";

export default {
  name: "ProgramsQueue",
  components: { Timeline },
  mixins: [lang],
  data() {
    return {
      id: "",
      name: "",
      selected: null,
      emails: {},
      emailsTimeline: [],
      form: {
        email: {
          customContent: {
            type: "editor",
            name: { fr: "Contenu spécifique", en: "Custom content" },
            placeholder: {
              fr: "Suite à notre appel d'hier...",
              en: "Following my call...",
            },
            editorToolbar: [
              ["bold", "italic", "underline", "link", "strike", "image"],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ align: [] }],
              ["clean"],
            ],
            required: false,
            validations: {},
          },
        },
      },
      loading: {
        queue: "PENDING",
        save: "KO",
      },
      content: {
        fr: {
          title: "Plannification des emails",
          recipients: "destinataires",
          emailPreview: "Aperçu de l'email",
          sent: "Envoyé",
          tosent: "En attente",
          timeline: {
            start: "Début de la campagne",
            end: "C'est terminé",
          },
          save: "Enregistrer",
          saveFormSuccess: "Enregistrement réussi",
          saveFormErrors: "Impossible d'enregistrer vos modifications",
        },
        en: {
          title: "Email scheduling",
          emailPreview: "Email preview",
          recipients: "recipients",
          sent: "Sent",
          tosent: "Waiting...",
          save: "Save",
          saveFormSuccess: "Saving changes was successful",
          saveFormErrors: "Unable to save your changes",
          timeline: {
            start: "Campaign start",
            end: "The end",
          },
        },
      },
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.$date.locale(this.getLang);
    this.init();
  },
  computed: {},
  methods: {
    init() {
      this.loading.queue = "PENDING";

      var getQueueApi = firebaseFunctions.httpsCallable("programsGetQueue");

      return getQueueApi({ id: this.id })
        .then((response) => {
          this.name = response.data.name;

          var emails = [];

          response.data.emails.forEach((email) => {
            this.$set(this.emails, email.id, email);

            emails.push({
              id: email.id,
              title: this.$date(email.date).format("dddd DD MMMM"),
              type: "date",
              content: email.subject,
              meta: email.sent
                ? this.content[this.getLang].sent
                : this.content[this.getLang].tosent,
              date: {
                raw: email.date,
                day: this.$date(email.date).format("D"),
                full: this.$date(email.date).format("YYYYMD"),
                formated: this.$date(email.date).format("dddd D MMMM YYYY"),
              },
              style: "secondary",
            });
          });

          this.emailsTimeline = [...emails];

          if (response.data.success) this.loading.queue = "OK";
          else this.loading.queue = "ERROR";
        })
        .catch((error) => {
          this.loading.queue = "ERROR";

          throw error;
        });
    },
    save: function(emailId) {
      if (this.loading.save === "PENDING") return;
      this.loading.save = "PENDING";

      var saveEmail = firebaseFunctions.httpsCallable("programsSaveQueue");

      return saveEmail({
        ...this.emails[emailId],
        locale: this.getLang,
      })
        .then(() => {
          this.loading.save = "OK";

          this.$toasted.show(this.content[this.getLang].saveFormSuccess, {
            position: "bottom-right",
            duration: 2000,
            type: "success",
          });
        })
        .catch(() => {
          this.loading.save = "ERROR";

          this.$toasted.show(this.content[this.getLang].saveFormErrors, {
            position: "bottom-right",
            duration: 2000,
            type: "error",
          });
        });
    },
  },
  watch: {
    selected: function() {
      if (this.emails[this.selected].html !== undefined) {
        this.$nextTick(function() {
          var doc = this.$refs.iframe.contentWindow.document;
          this.$refs.iframe.contentWindow.window.scrollTo(0, 0);
          doc.open();
          doc.write(this.emails[this.selected].html);
          doc.close();
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
main {
  padding-top: 48px;
}

.queue__main {
  width: 100%;
  display: flex;

  .queue {
    &__menu,
    &__workspace {
      padding-top: $space-m;
      padding-bottom: $space-m;
      padding-bottom: 100px;
      height: calc(100vh - 101px);

      overflow: scroll;
    }

    &__menu {
      width: 400px;
      flex-basis: 400px;
      flex-grow: 0;
      flex-shrink: 0;
      user-select: none;
      padding: $space-m $space-xl;
    }

    &__workspace {
      width: 100%;
      min-height: 300px;
      padding-right: $space-xl;

      .editor {
        border-radius: $border-radius-m;
        border: $border;
        background: $color-neutral-100;
        box-shadow: $box-shadow-l;
        padding: $space-s;
        width: 100%;

        &__header {
          @include flex;
          border-bottom: 1px solid $color-neutral-90;
          padding-bottom: $space-xs;
          align-items: center;

          .left {
            width: 100%;
          }
          .right {
            flex: 0 0 auto;
          }
        }

        &__subject {
          @include text-large-black;
        }

        &__preview-container {
          margin-bottom: $space-base;
        }

        &__preview {
          @include text-body-black;
          margin-top: $space-base;
          margin-bottom: $space-xs;
        }

        &__date {
          text-transform: capitalize;
        }

        &__status {
          @include flex;
          > * {
            margin-left: $space-xxs;
          }
        }

        .email {
          &__fieldgroup {
            margin-bottom: $space-base;
          }
        }

        iframe {
          height: 400px;
          width: 100%;
          border-radius: $border-radius-m;
          border: $border;
        }
      }
    }
  }
}

header {
  padding: $space-s;
  @include text-large-black;
}
</style>
