<template>
  <div class="campaign">
    <mks-loading v-if="loading.campaign !== 'OK'"></mks-loading>
    <div v-else>
      <header class="campaign__header">
        <h1 v-on:click="editName = true" v-if="!editName">
          > {{ campaign.name }}
          <span class="edit">{{ content[lang].templateEditName }}</span>
        </h1>
        <mks-field
          v-else
          name="campaignName"
          id="campaignName"
          type="text"
          placeholder="Campaign name #2"
          v-model="campaign.name"
        />
      </header>

      <main class="templates">
        <nav class="templates__menu">
          <div
            v-for="currentTemplate in orderedTemplates"
            :key="currentTemplate.key"
            class="menu__item"
            :class="{
              '--active': template === currentTemplate.key,
              '--errors': $v.templates[currentTemplate.key].$invalid,
              '--draft': currentTemplate.status === 'Draft',
            }"
            v-on:click="changeTemplate(currentTemplate.key)"
          >
            <span class="item__day">+{{ currentTemplate.relativeDay }}</span>
            <span class="item__name">
              {{
                currentTemplate.name !== ""
                  ? currentTemplate.name
                  : content[lang].templateWithoutName
              }}
            </span>
          </div>
          <div
            class="menu__item new_template"
            v-on:click="() => (template = 'new')"
          >
            {{ content[lang].templateNewOptionLabel }}
          </div>
        </nav>
        <div class="templates__workspace" :key="template">
          <div
            class="templates__edition"
            v-if="!['unselected', 'new'].includes(template)"
          >
            <div
              class="template__fieldgroup"
              v-for="(field, index) in form.template"
              :key="index"
            >
              <mks-field-label>{{ field.name[lang] }}</mks-field-label>
              <mks-field
                class="fieldgroup__field"
                :name="index"
                :id="index"
                :type="field.type"
                :placeholder="
                  field.placeholder ? field.placeholder[lang] : null
                "
                :description="
                  field.description ? field.description[lang] : null
                "
                :error="
                  $v.templates[template][index] !== undefined
                    ? $v.templates[template][index].$invalid
                    : false
                "
                :options="field.options"
                :min="field.min"
                :max="field.max"
                v-model="templates[template][index]"
                :editorToolbar="field.editorToolbar"
              />
            </div>
            <div class="edition__actions">
              <mks-button tag="button" @click="onOpenEditor">{{
                content[lang].editEmail
              }}</mks-button>
              <mks-button
                tag="button"
                type="neutral"
                @click="duplicateTemplate(template)"
                >{{ content[lang].duplicateTemplate }}</mks-button
              >
              <mks-button
                tag="button"
                type="neutral"
                @click="deleteTemplate(template)"
                >{{ content[lang].deleteTemplate }}</mks-button
              >
            </div>
          </div>
          <div class="popup__email" v-show="editor">
            <div class="email__header">
              <div class="header__left" v-if="templates[template]">
                {{ templates[template].name }}
              </div>
              <div class="header__right">
                <div class="action" v-on:click="editor = false">
                  {{ content[lang].cancel }}
                </div>
                <mks-button
                  tag="button"
                  type="positive"
                  class="action"
                  @click="saveDesign"
                  >{{ content[lang].emailEditorSave }}</mks-button
                >
              </div>
            </div>
            <div class="email__main">
              <div
                v-show="loading.emailStructure === 'PENDING'"
                class="email__loading"
              >
                <mks-loading type="standalone"></mks-loading>
              </div>
              <EmailEditor
                ref="editor"
                v-on:load="editorLoaded"
                class="email__editor"
                :options="emailEditorOptions"
              />
            </div>
          </div>

          <footer class="templates__footer">
            <div class="footer__infos">
              <template v-if="this.campaign.status !== 'Archived'">
                <div class="infos__errors" v-if="$v.$invalid">
                  ❌ {{ content[lang].formErrors }}
                </div>
              </template>
              <div v-else>🔒 {{ content[lang].statusArchived }}</div>
            </div>
            <div
              class="footer__actions"
              v-if="this.campaign.status !== 'Archived'"
            >
              <mks-button
                class="duplicate__campaign"
                tag="button"
                type="neutral"
                @click="duplicate()"
                :loading="loading.duplicate === 'PENDING'"
              >
                {{ content[lang].duplicateButton }}
                <template v-slot:loading>
                  {{ content[lang].duplicateButtonLoading }}
                </template>
              </mks-button>
              <mks-button
                class="archive__campaign"
                tag="button"
                type="neutral"
                @click="archive()"
                :loading="loading.archive === 'PENDING'"
              >
                {{ content[lang].archiveButton }}
                <template v-slot:loading>
                  {{ content[lang].archiveButtonLoading }}
                </template>
              </mks-button>
              <mks-button
                tag="button"
                type="positive"
                :disabled="!touch"
                @click="save()"
                :loading="loading.save === 'PENDING'"
              >
                {{ content[lang].saveButton }}
                <template v-slot:loading>
                  {{ content[lang].saveButtonLoading }}
                </template>
              </mks-button>
            </div>
          </footer>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import { functions as firebaseFunctions } from "@/modules/firebase";
import { validationMixin } from "vuelidate";
import { required, integer, minValue } from "vuelidate/lib/validators";
import { EmailEditor } from "vue-email-editor";
import lang from "../../mixins/lang.js";
import orderBy from "lodash.orderby";

export default {
  components: { EmailEditor },
  mixins: [validationMixin, lang],
  data: function() {
    return {
      name: "",
      editName: false,
      id: null,
      timestamp: null,
      campaign: null,
      templates: null,
      touch: false,
      template: "unselected",
      templateValues: {},
      uniqueIncrement: 0,
      layout: null,
      loading: {
        campaign: null,
        archive: null,
        save: null,
        duplicate: null,
        emailStructure: false,
      },
      editor: false,
      form: {
        template: {
          name: {
            type: "text",
            name: { fr: "Nom du template", en: "Template name" },
            placeholder: { fr: "Template #1...", en: "Template #1..." },
            required: true,
            validations: {
              required,
            },
          },
          subject: {
            type: "text",
            name: { fr: "Objet du mail", en: "Mail object" },
            placeholder: {
              fr: "Hello *|firstName|* ...",
              en: "Hello *|firstName|* ...",
            },
            description: {
              fr:
                "Vous pouvez utiliser les variables suivantes : *|firstName|*, *|lastName|*, *|fullName|*, *|email|*, *|promo|*, *|city|*, *|weekNumber|*",
              en:
                "You can use the following variables: *|firstName|*, *|lastName|*, *|fullName|*, *|email|*, *|promo|*, *|city|*, *|weekNumber|*",
            },
            validations: {
              required,
            },
          },
          from: {
            type: "text",
            name: { fr: "Nom de l'expediteur", en: "Sender's name" },
            placeholder: {
              fr: "makesense",
              en: "makesense",
            },
            validations: {
              required,
            },
          },
          relativeDay: {
            type: "number",
            min: 0,
            name: {
              fr: "Jour d'envoi (relatif au début de la campagne)",
              en: "Sending day (relative to the start of the campaign)",
            },
            placeholder: {
              fr: "1",
              en: "1",
            },
            validations: {
              required,
              integer,
              minValue: minValue(0),
            },
          },
          status: {
            type: "select",
            name: {
              fr: "Statut de publication",
              en: "Status",
            },
            validations: {
              required,
            },
            options: {
              Draft: {
                label: "Draft",
                value: "Draft",
              },
              Published: {
                label: "Published",
                value: "Published",
              },
            },
            description: {
              fr:
                "Si le statut est \"Draft\" il ne sera pas intégré à la suite d'email au moment de la configuration d'une campagne. Publiez votre template quand il est prêt à être intégré.",
              en:
                'If the status is "Draft" it will not be integrated into the email suite when configuring a campaign. Publish your template when it is ready to be integrated.',
            },
          },
        },
      },
      content: {
        fr: {
          duplicateButton: "Dupliquer",
          duplicateButtonLoading: "Duplication...",
          confirmDuplicate: "Confirmez-vous vouloir dupliquer cette campagne ?",
          templateUnselectedOptionLabel: "Sélectionnez un template à modifier",
          templateNewOptionLabel: "Ajouter un template",
          confirmArchiving:
            "Souhaitez-vous réellement archiver cette campagne ? Vous ne pourrez plus y accéder ou la modifier.",
          statusArchived:
            "Cette campagne est archivée, les modifications ne seront pas enregistrées",
          days: "jour",
          templateEditName: "Modifier le nom",
          copyOf: "Copie de",
          templateForm: {
            name: {
              placeholder: "Nom de la campagne",
            },
            subject: {
              placeholder: "Objet du mail",
            },
          },
          mergeTags: {
            firstName: "Prénom",
            lastName: "Nom",
            fullName: "Prénom Nom",
            email: "Email",
            promo: "ID de promotion",
            weekNumber: "Numéro de semaine",
            city: "Ville",
            edition: "Edition",
            whatsapp: "Lien du groupe Whatsapp",
            region: "Region",
            customContent: "Contenu personnalisé",
            actionsDashboard: "Lien de suivi des actions",
          },
          templateWithoutName: "Template sans titre",
          formErrors: "Il y a des erreurs",
          saveFormErrors: "Vous devez corriger les erreurs avant d'enregistrer",
          saveButton: "Enregistrer les modifications",
          saveButtonLoading: "Mise à jour...",
          archiveButton: "Archiver",
          archiveButtonLoading: "Archivage...",
          archiveCampaignSuccess: "La campagne a été archivée avec succès",
          saveSuccess: "Mise à jour réussie",
          cancel: "Annuler les modifications",
          templateWithErrors: "Erreurs détectées",
          emailEditorSave: "Fermer",
          editEmail: "Editer le template email",
          deleteTemplate: "Archiver le template",
          duplicateTemplate: "Dupliquer le template",
          duplicateSuccess: "Duplication du template réussie",
          deleteTemplateConfirmation:
            "Confirmez-vous vouloir archiver ce template ?",
          duplicateCampaignSuccess: "La campagne a été dupliquée avec succès",
          duplicateCampaignError: "Impossible de dupliquer la campagne",
        },
        en: {
          duplicateButton: "Duplicate",
          templateEditName: "Edit",

          duplicateButtonLoading: "Duplication...",
          confirmDuplicate:
            "Do you confirm that you want to duplicate this campaign?",
          confirmArchiving:
            "Are you sure you want to archive this campaign? You will no longer be able to access or modify it.",
          templateUnselectedOptionLabel: "Select a template to modify",
          archiveCampaignSuccess: "The campaign has been successfully archived",
          templateNewOptionLabel: "+ Add a new template",
          statusArchived: "This campaign is archived",
          days: "day",
          copyOf: "Copy of",
          templateForm: {
            name: {
              placeholder: "Campaign name",
            },
            subject: {
              placeholder: "Mail object / subject",
            },
          },
          mergeTags: {
            firstName: "Firstname",
            lastName: "Lastname",
            fullName: "Firstname and name",
            email: "Email",
            edition: "Edition",
            region: "Region",
            promo: "Promotion ID",
            weekNumber: "Week number",
            whatsapp: "Whatsapp group link",
            city: "City",
            customContent: "Custom content",
            actionsDashboard: "Actions tracking link",
          },
          templateWithoutName: "Untitled template",
          formErrors: "There are errors",
          cancel: "Cancel",
          archiveButton: "Archive campaign",
          archiveButtonLoading: "Archiving...",
          saveFormErrors: "You must correct the errors before saving",
          saveButton: "Save modifications",
          saveButtonLoading: "Updating...",
          saveSuccess: "Update successful",
          templateWithErrors: "Errors detected",
          emailEditorSave: "Close",
          editEmail: "Edit email template",
          deleteTemplate: "Archive template",
          duplicateTemplate: "Duplicate template",
          duplicateSuccess: "Duplication successful",
          deleteTemplateConfirmation:
            "Do you confirm that you want to archive the template?",
        },
      },
    };
  },
  computed: {
    lang() {
      return this.getLang;
    },
    emailEditorOptions: function() {
      return {
        projectId: 5572,
        locale: this.getLang === "fr" ? "fr-FR" : "en-US",
        customJS: ["https://programs.makesense.org/hello.js"],
        mergeTags: {
          firstName: {
            name: this.content[this.lang].mergeTags.firstName,
            value: "{{firstName}}",
          },
          lastName: {
            name: this.content[this.lang].mergeTags.lastName,
            value: "{{lastName}}",
          },
          fullName: {
            name: this.content[this.lang].mergeTags.fullName,
            value: "{{fullName}}",
          },
          email: {
            name: this.content[this.lang].mergeTags.email,
            value: "{{email}}",
          },
          promo: {
            name: this.content[this.lang].mergeTags.promo,
            value: "{{promo}}",
          },
          whatsapp: {
            name: this.content[this.lang].mergeTags.whatsapp,
            value: "{{whatsapp}}",
          },
          city: {
            name: this.content[this.lang].mergeTags.city,
            value: "{{city}}",
          },
          region: {
            name: this.content[this.lang].mergeTags.region,
            value: "{{region}}",
          },
          edition: {
            name: this.content[this.lang].mergeTags.edition,
            value: "{{edition}}",
          },
          customContent: {
            name: this.content[this.lang].mergeTags.customContent,
            value: "{{{customContent}}}",
          },
          actionsDashboard: {
            name: this.content[this.lang].mergeTags.actionsDashboard,
            value: "{{actionsDashboard}}",
          },
        },
      };
    },
    templatesAsOptions: function() {
      var options = {
        unselected: {
          label: this.content[this.lang].templateUnselectedOptionLabel,
          value: "unselected",
        },
      };

      for (const property in this.templates) {
        var template = this.templates[property];
        if (template.id && template.status !== "Archived") {
          var name = template.name;
          if (template.name === "")
            name = this.content[this.lang].templateWithoutName;

          var label =
            name +
            " (+" +
            template.relativeDay +
            " " +
            this.content[this.lang].days +
            ")";

          if (this.$v.templates[property].$invalid)
            label += " - " + this.content[this.lang].templateWithErrors;

          options[property] = {
            label: label,
            value: property,
          };
        }
      }

      options.new = {
        label: this.content[this.lang].templateNewOptionLabel,
        value: "new",
      };

      return options;
    },
    orderedTemplates() {
      var options = {};

      for (const property in this.templates) {
        if (this.templates[property].status !== "Archived") {
          options[property] = this.templates[property];
          options[property].key = property;
        }
      }

      return orderBy(options, "relativeDay", "asc");
    },
  },
  created() {
    this.init();
    window.onbeforeunload = (e) => {
      if (this.touch) {
        e = e || window.event;
        if (e) {
          e.returnValue = "Sure?";
        }

        // For Safari
        return "Sure?";
      }
    };
  },
  mounted: function() {},
  methods: {
    init: function() {
      this.id = this.$route.params.id;
      this.getCampaign(this.id);
      this.timestamp = Date.now();
    },
    getCampaign: function() {
      this.loading.campaign = "PENDING";

      var getUserCampaigns = firebaseFunctions.httpsCallable(
        "programsGetCampaign"
      );

      return getUserCampaigns({ id: this.id })
        .then((response) => {
          this.campaign = response.data.campaign;
          this.templates = {};
          response.data.templates.forEach((template) => {
            this.$set(this.templates, template.id, template);
          });

          this.$v.$touch();

          if (response.data.success) this.loading.campaign = "OK";
          else this.loading.campaign = "ERROR";

          if (this.campaign.layout !== undefined && this.campaign.layout)
            this.getLayout(this.campaign.layout);
        })
        .catch((error) => {
          this.loading.campaign = "ERROR";

          throw error;
        });
    },
    save: function() {
      if (this.loading.save === "PENDING") return;

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toasted.show(this.content[this.lang].saveFormErrors, {
          position: "bottom-right",
          duration: 2000,
          type: "error",
        });
      } else {
        this.loading.save = "PENDING";

        var saveCampaign = firebaseFunctions.httpsCallable(
          "programsSaveCampaign"
        );

        return saveCampaign({
          campaign: this.id,
          name: this.campaign.name,
          templates: this.templates,
        })
          .then((response) => {
            this.loading.save = "OK";
            this.$toasted.show(this.content[this.lang].saveSuccess, {
              theme: "toasted-primary",
              position: "bottom-right",
              duration: 2000,
            });

            // En cas de création de template au moment la sauvegarde on récupère le record id et on remplace l'id temporaire
            if (
              response.data.created !== undefined &&
              response.data.created.length > 0
            ) {
              response.data.created.forEach((template) => {
                this.templates[template.id].new = false;
                this.$set(this.templates[template.id], "id", template.recordId);
              });
            }

            setTimeout(() => {
              this.touch = false;
            }, 500);
          })
          .catch((error) => {
            this.loading.save = "ERROR";

            throw error;
          });
      }
    },
    archive: function() {
      if (this.loading.archive === "PENDING") return;

      if (window.confirm(this.content[this.lang].confirmArchiving)) {
        this.loading.archive = "PENDING";

        var saveCampaign = firebaseFunctions.httpsCallable(
          "programsArchiveCampaign"
        );

        return saveCampaign({
          id: this.id,
        })
          .then(() => {
            this.$toasted.show(this.content[this.lang].archiveCampaignSuccess, {
              position: "bottom-right",
              duration: 2000,
              type: "success",
            });

            this.$router.push({
              name: "programsHome",
              params: {},
            });
          })
          .catch(() => {
            this.loading.archive = "ERROR";
          });
      }
    },
    duplicate: function() {
      if (this.loading.duplicate === "PENDING") return;
      if (window.confirm(this.content[this.lang].confirmDuplicate)) {
        this.loading.duplicate = "PENDING";

        var duplicateCampaign = firebaseFunctions.httpsCallable(
          "programsDuplicateCampaign"
        );

        return duplicateCampaign({
          id: this.id,
        })
          .then((response) => {
            this.loading.duplicate = "OK";

            this.$toasted.show(
              this.content[this.lang].duplicateCampaignSuccess,
              {
                position: "bottom-right",
                duration: 2000,
                type: "success",
              }
            );

            this.$router.push({
              name: "campaign",
              params: { id: response.data },
              query: { lang: this.getLang },
            });
          })
          .catch(() => {
            this.loading.duplicate = "ERROR";

            this.$toasted.show(this.content[this.lang].duplicateCampaignError, {
              position: "bottom-right",
              duration: 2000,
              type: "error",
            });
          });
      }
    },
    getLayout: function(id) {
      this.loading.layout = "PENDING";

      var getLayout = firebaseFunctions.httpsCallable("programsGetLayout");

      return getLayout({ id: id })
        .then((response) => {
          this.layout = response.data;
          this.loading.layout = "OK";
        })
        .catch((error) => {
          this.loading.layout = "ERROR";
          throw error;
        });
    },
    changeTemplate(id) {
      this.template = id;
    },
    editorLoaded() {
      // eslint-disable-next-line no-undef
      unlayer.addEventListener("design:loaded", this.onDesignLoaded);
    },
    onDesignLoaded() {},
    saveDesign() {
      this.$refs.editor.saveDesign((design) => {
        this.templates[this.template].emailEditor = JSON.stringify(design);
        this.$refs.editor.exportHtml((data) => {
          this.templates[this.template].content = data.html;
        });
      });

      this.editor = false;
    },
    deleteTemplate(id) {
      var confirm = window.confirm(
        this.content[this.lang].deleteTemplateConfirmation
      );
      if (confirm) {
        this.template = "unselected";

        if (this.templates[id].new) this.$delete(this.templates, id);
        else this.$set(this.templates[id], "status", "Archived");
      }
    },
    duplicateTemplate(id) {
      var duplicatedTemplate = this.templates[id];

      this.createTemplate({
        name: this.content[this.lang].copyOf + " : " + duplicatedTemplate.name,
        subject: duplicatedTemplate.subject,
        from: duplicatedTemplate.from,
        fromMail: duplicatedTemplate.fromMail,
        relativeDay: duplicatedTemplate.relativeDay,
        emailEditor: duplicatedTemplate.emailEditor,
      });

      this.$toasted.show(this.content[this.lang].duplicateSuccess, {
        theme: "toasted-primary",
        position: "bottom-right",
        duration: 2000,
      });
    },
    createTemplate(duplicateModel = {}) {
      var id = "new" + this.uniqueIncrement;

      var model = {
        name: this.content[this.lang].templateWithoutName,
        subject: "",
        from: "",
        fromMail: "",
        content: "",
        relativeDay: 0,
        emailEditor: null,
        ...duplicateModel,
        status: "Draft",
        id: id,
        new: true,
      };

      this.$set(this.templates, id, model);
      this.uniqueIncrement++;
      this.timestamp = Date.now();
      this.$nextTick(() => {
        this.template = id;
      });
    },
    onOpenEditor: function() {
      this.editor = true;
      this.loadDesignEmail();
    },
    loadDesignEmail: function() {
      this.loading.emailStructure = "PENDING";
      var content = {
        counters: {
          u_column: 2,
          u_row: 2,
          u_content_button: 1,
          u_content_image: 1,
          u_content_text: 1,
        },
        body: {
          rows: [
            {
              cells: [1],
              columns: [
                {
                  contents: [
                    {
                      type: "image",
                      values: {
                        containerPadding: "10px",
                        _meta: {
                          htmlID: "u_content_image_1",
                          htmlClassNames: "u_content_image",
                        },
                        selectable: false,
                        draggable: false,
                        duplicatable: false,
                        deletable: false,
                        src: {
                          url:
                            "https://unroll-images-production.s3.amazonaws.com/projects/0/1591791700307-b61bdc6d-6ca5-4707-8b82-9de82913ca3f.png",
                          width: 1724,
                          height: 518,
                          autoWidth: false,
                          maxWidth: "42%",
                        },
                        textAlign: "center",
                        altText: "Image",
                        action: {
                          name: "web",
                          values: { href: "", target: "_blank" },
                        },
                      },
                    },
                  ],
                  values: {
                    backgroundColor: "",
                    padding: "0px",
                    border: {},
                    _meta: {
                      htmlID: "u_column_1",
                      htmlClassNames: "u_column",
                    },
                  },
                },
              ],
              values: {
                id: "header",
                columns: false,
                backgroundColor: "",
                columnsBackgroundColor: "",
                backgroundImage: {
                  url:
                    "https://unroll-images-production.s3.amazonaws.com/projects/0/1591791755427-ea268464-6ef1-4473-8b32-891ae00b9980.jpg",
                  width: 1920,
                  height: 786,
                  fullWidth: true,
                  repeat: false,
                  center: true,
                },
                padding: "40px 0px",
                hideDesktop: false,
                hideMobile: false,
                noStackMobile: false,
                _meta: { htmlID: "u_row_1", htmlClassNames: "u_row" },
                selectable: false,
                draggable: false,
                duplicatable: false,
                deletable: false,
              },
            },
            {
              cells: [1],
              columns: [
                {
                  contents: [
                    {
                      type: "text",
                      values: {
                        containerPadding: "10px",
                        _meta: {
                          htmlID: "u_content_text_1",
                          htmlClassNames: "u_content_text",
                        },
                        selectable: true,
                        draggable: true,
                        duplicatable: true,
                        deletable: false,
                        color: "#000000",
                        textAlign: "center",
                        lineHeight: "140%",
                        linkStyle: {
                          inherit: true,
                          linkColor: "#0000ee",
                          linkHoverColor: "#0000ee",
                          linkUnderline: true,
                          linkHoverUnderline: true,
                        },
                        text:
                          '<p style="font-size: 14px; line-height: 140%;"><span style="font-size: 14px; line-height: 19.6px;">Makesense.</span></p>',
                      },
                    },
                  ],
                  values: {
                    backgroundColor: "",
                    padding: "0px",
                    border: {},
                    _meta: {
                      htmlID: "u_column_2",
                      htmlClassNames: "u_column",
                    },
                  },
                },
              ],
              values: {
                id: "footer",
                columns: false,
                backgroundColor: "",
                columnsBackgroundColor: "",
                backgroundImage: {
                  url: "",
                  fullWidth: true,
                  repeat: false,
                  center: true,
                  cover: false,
                },
                padding: "40px 0px",
                hideDesktop: false,
                hideMobile: false,
                noStackMobile: false,
                _meta: { htmlID: "u_row_2", htmlClassNames: "u_row" },
                selectable: true,
                draggable: false,
                duplicatable: false,
                deletable: false,
              },
            },
          ],
          values: {
            backgroundColor: "#ffffff",
            backgroundImage: {
              url: "",
              fullWidth: true,
              repeat: false,
              center: true,
              cover: false,
            },
            contentWidth: "600px",
            fontFamily: {
              label: "Raleway",
              value: "'Raleway',sans-serif",
              url: "https://fonts.googleapis.com/css?family=Raleway:400,700",
              defaultFont: true,
            },
            linkStyle: {
              body: true,
              linkColor: "#0000ee",
              linkHoverColor: "#0000ee",
              linkUnderline: true,
              linkHoverUnderline: true,
            },
            _meta: { htmlID: "u_body", htmlClassNames: "u_body" },
          },
        },
      };

      if (this.layout) content = JSON.parse(this.layout.editor);

      if (
        this.templates[this.template].emailEditor !== undefined &&
        this.templates[this.template].emailEditor &&
        this.templates[this.template].emailEditor !== ""
      )
        content = JSON.parse(this.templates[this.template].emailEditor);

      this.$refs.editor.loadDesign(content);

      this.$nextTick(() => {
        setTimeout(() => {
          this.loading.emailStructure = "OK";
        }, 1000);
      });
    },
  },
  validations() {
    var fieldsValidations = {};

    Object.keys(this.templates).forEach((template) => {
      fieldsValidations[template] = {};

      Object.keys(this.form.template).forEach((field) => {
        if (this.form.template[field].validations !== undefined)
          fieldsValidations[template][field] = this.form.template[
            field
          ].validations;
      });
    });

    return {
      templates: {
        ...fieldsValidations,
      },
    };
  },
  watch: {
    templates: {
      deep: true,
      handler(newValue, oldValue) {
        if (oldValue) this.touch = true;
      },
    },
    "$route.params.id"() {
      this.$router.go(this.$router.currentRoute);
    },
    "campaign.name": {
      handler(newValue, oldValue) {
        if (oldValue) this.touch = true;
      },
    },
    template: function() {
      if (["unselected"].includes(this.template)) return;

      if (this.template === "new") {
        this.createTemplate();
        return;
      }

      this.editor = false;
    },
    editor: function() {},
  },
};
</script>
<style lang="scss" scoped>
.campaign {
  padding-top: 50px;

  &__header {
    padding: $space-s;

    h1 {
      @include text-body-black;
      .edit {
        margin-left: $space-xxs;
        @include text-small;
        font-weight: $font-weight-bold;
        color: $text-color-link;
        @include cursor-pointer;
      }
    }
  }
}
.templates {
  width: 100%;

  display: flex;
  flex: 1 0 auto;
  &__menu,
  &__workspace {
    padding-top: $space-m;
    padding-bottom: $space-m;
    padding-bottom: 100px;
  }

  &__menu {
    width: 226px;
    user-select: none;
    padding: $space-m $space-s;
    padding-bottom: 100px;

    .menu {
      &__item {
        padding: 10px;
        cursor: pointer;
        border-radius: $border-radius-m;
        font-size: $font-size-s;
        text-overflow: ellipsis;
        word-wrap: break-word;
        font-weight: $font-weight-extrabold;

        &:hover {
          background: $color-neutral-95;
          opacity: 1;
        }

        &.--draft {
          opacity: 0.5;

          &:hover {
            opacity: 1;
          }
        }

        &.--active,
        &.--active.--draft {
          background: $color-neutral-90;
          font-weight: $font-weight-black;
          opacity: 1;

          .item__day {
            background-color: #fff;
          }
        }

        &.--errors {
          .item__name {
            color: $color-danger-active;
          }
        }

        &.new_template {
          background-color: $color-success;
          color: #fff;
          text-align: center;
          margin-top: $space-s;
        }

        .item {
          margin-bottom: 10px;
          &__day {
            border-radius: $border-radius-s;
            margin-right: 0.4em;
            padding: 0.1em 0.2em;
            border: 1px solid $border-color-base;
          }
        }
      }
    }
  }

  &__workspace {
    flex: 1;
    overflow-x: hidden;
    position: relative;
    padding: 20px;
    padding-bottom: 100px;
  }

  &__edition {
    border-radius: $border-radius-m;
    border: $border;
    background: $color-neutral-100;
    box-shadow: $box-shadow-l;
    padding: $space-s;

    .edition__actions {
      > * {
        margin-right: $space-s;
      }
    }
  }

  &__footer {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 100;
    background: #fff;
    padding: $space-s;
    @include flex;
    align-items: center;
    border-top: 1px solid $border-color-base;

    .footer {
      &__actions {
        @include flex;

        flex-grow: 2;
        justify-content: flex-end;
      }

      &__infos {
        .infos {
          &__errors {
            @include text-body-black;
            color: $color-danger;
          }
        }
      }
    }
    .archive__campaign,
    .duplicate__campaign {
      margin-right: $space-s;
    }
  }

  .template {
    &__fieldgroup {
      margin-bottom: $space-m;
    }

    &__emaileditor {
      height: 700px;
    }
  }
}

.popup__email {
  @include flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1000;
  background-color: #fff;

  .email {
    &__main,
    &__header {
    }

    &__header {
      @include flex;
      align-items: center;
      border-bottom: $border;

      flex-grow: 0;
      flex-shrink: 0;
      padding: $space-s;

      .header {
        &__left {
          @include text-large-black;
        }

        &__right {
          @include flex;
          align-items: center;
          flex-grow: 2;
          justify-content: flex-end;

          .action {
            margin-left: $space-s;
            cursor: pointer;
          }
        }
      }
    }

    &__loading {
      position: absolute;
      height: 100%;
      z-index: 100;
      background-color: #fff;
      left: 0px;
      top: 0px;
      bottom: 0px;
      right: 0px;
      @include flex;
      align-items: center;
      justify-content: center;
    }

    &__main {
      flex-grow: 2;
      height: calc(100vh - 84px);
    }

    &__editor {
      height: 100%;

      .blockbuilder-branding {
        display: none !important;
      }
    }
  }
}
</style>
