<template>
  <div class="fullModal">
    <header>
      <div class="header__left">
        <slot name="header__left"></slot>
      </div>
      <div class="header__right">
        <slot name="header__right"></slot>
      </div>
    </header>
    <main>
      <slot />
    </main>
  </div>
</template>

<script>
export default {
  name: "FullModal"
};
</script>

<style lang="scss" scoped>
.fullModal {
  @include flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1000;
  background-color: #fff;

  header {
    @include flex;
    align-items: center;
    border-bottom: $border;

    flex-grow: 0;
    flex-shrink: 0;
    padding: $space-s;

    min-height: 84px;

    .header {
      &__left {
        @include text-large-black;
      }

      &__right {
        @include flex;
        align-items: center;
        flex-grow: 2;
        justify-content: flex-end;

        .action {
          margin-left: $space-s;
          cursor: pointer;
        }
      }
    }
  }

  main {
    flex-grow: 2;
    height: calc(100vh - 84px);
  }
}
</style>