<template>
  <main class="makesenseTV">
    <header class="app__header">
      <div class="header__container">
        <div class="header__links">
          <img
            src="@/assets/makesenseTV/brand/mkstv_color.png"
            class="logotype__image"
          />
          <router-link class="menu__item" :to="{ name: 'makesenseTvHome' }"
            >📺 Agenda</router-link
          >
          <a
            href="http://spreadsense.makesense.org/"
            target="_blank"
            class="menu__item"
            >🚀 #spreadsensenotcorona</a
          >
          <a class="menu__item" href="https://makesense.org" target="_blank"
            >❤️ makesense</a
          >
        </div>
      </div>
    </header>
    <div class="app__workspace">
      <router-view />
    </div>
  </main>
</template>

<script>
export default {
  name: "makesenseTvApp",
  computed: {},
  methods: {},
  created() {
    // loads the makesense_tv design tokens (ie. color scheme)
    this.$loadTheme("tv");
  },
};
</script>

<style lang="scss" scoped>
.app__header {
  position: sticky;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
  padding: 10px;
  // todo : find how to use design system scss variables here
  background-color: var(--color-neutral-100);
  overflow: hidden;
}

.header__container {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-row: 1;
  grid-column: content;
  align-self: center;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

.header__links,
.header__profile,
.header__left {
  display: flex;
  align-items: center;
  height: 100%;
}

.menu__item {
  margin-left: 40px;
  text-decoration: none;
  @include text-large-black;
  color: var(--color-text);
}

.logotype__image {
  width: 71px;
  height: auto;
}
</style>
