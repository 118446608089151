<template>
  <main class="create">
    <mks-loading v-if="loading.create === 'PENDING'" type="component"></mks-loading>
    <div class="create__form" v-else-if="loading.create === 'KO'">
      <h1>{{ content[getLang].createTitle }}</h1>
      <div class="campaign__fieldgroup" v-for="(field, index) in form" :key="index">
        <mks-field-label>{{ field.name[getLang] }}</mks-field-label>
        <mks-field
          class="fieldgroup__field"
          :name="index"
          :id="index"
          :type="field.type"
          :placeholder="
                  field.placeholder ? field.placeholder[getLang] : null
                "
          :description="
                  field.description ? field.description[getLang] : null
                "
          :error="
                  $v.campaign[index] !== undefined && touch
                    ? $v.campaign[index].$invalid
                    : false
                "
          :options="typeof field.options !== 'string' ? field.options : superComputed(field.options)"
          :min="field.min"
          :max="field.max"
          v-model="campaign[index]"
          :editorToolbar="field.editorToolbar"
        />
      </div>
      <div class="create__actions">
        <mks-button tag="button" type="positive" @click="onCreate">
          {{
          content[getLang].createLabel
          }}
        </mks-button>
      </div>
    </div>
  </main>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import lang from "../../mixins/lang.js";
import { functions as firebaseFunctions } from "@/modules/firebase";

export default {
  name: "ProgramsCreate",
  components: {},
  mixins: [validationMixin, lang],
  data() {
    return {
      id: "",
      touch: false,
      layouts: [],
      loading: {
        create: "KO"
      },
      content: {
        fr: {
          createLabel: "Créer la campagne",
          createTitle: "Nouvelle campagne",
          createFormErrors: "Le formulaire contient des erreurs"
        },
        en: {
          createLabel: "Create campaign",
          createTitle: "New campaign",
          createFormErrors: "The form contains errors"
        }
      },
      campaign: {
        name: "",
        description: "",
        email: "",
        layout: ""
      },
      form: {
        name: {
          type: "text",
          name: { fr: "Titre de la campagne", en: "Campaign title" },
          placeholder: { fr: "Ma campagne #1...", en: "My campaign #1..." },
          required: true,
          validations: {
            required
          }
        },
        description: {
          type: "textarea",
          name: {
            fr: "Description de la campagne",
            en: "Campaign description"
          },
          placeholder: {
            fr: "Cette campagne a ...",
            en: "This campaign is for..."
          },
          description: {
            fr:
              "Donnez un contexte vous permettant de facilement retrouver une campagne ou expliquer son but / utilité pour les opérateurs.",
            en:
              "Give a context allowing you to easily find a campaign or explain its purpose / usefulness for operators."
          },
          validations: {
            required
          }
        },
        email: {
          type: "email",
          name: { fr: "Votre adresse email", en: "Your email" },
          placeholder: {
            fr: "mon_adresse@email.com",
            en: "my_email@email.com"
          },
          description: {
            fr:
              "Vous permettra de retrouver facilement le lien d'édition de la campagne si vous le perdez.",
            en:
              "Will allow you to easily find the campaign edit link if you lose it."
          },
          validations: {
            required,
            email
          }
        },
        layout: {
          type: "select",
          name: { fr: "Layout associé", en: "Associated layout" },
          options: "getLayoutsAsOptions",
          validations: {
            required
          }
        }
      }
    };
  },
  created() {
    this.getLayouts();
  },
  mounted() {},
  computed: {
    getLayoutsAsOptions: function() {
      var options = {};

      this.layouts.forEach(layout => {
        options[layout.id] = {
          label: layout.name,
          value: layout.id
        };
      });

      return options;
    }
  },
  methods: {
    superComputed: function(getterName) {
      return this[getterName];
    },
    onCreate: function() {
      this.touch = true;
      if (this.loading.create === "PENDING") return;

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toasted.show(this.content[this.lang].createFormErrors, {
          position: "bottom-right",
          duration: 2000,
          type: "error"
        });
      } else {
        this.loading.create = "PENDING";

        var createCampaign = firebaseFunctions.httpsCallable(
          "programsCreateCampaign"
        );

        return createCampaign({
          ...this.campaign,
          locale: this.getLang
        })
          .then(response => {
            this.$router.push({
              name: "campaign",
              params: { id: response.data },
              query: { lang: this.getLang },
              locale: this.getLang
            });
          })
          .catch(() => {
            this.loading.create = "KO";
          });
      }
    },
    getLayouts: function() {
      var programsGetLayouts = firebaseFunctions.httpsCallable(
        "programsGetLayouts"
      );

      return programsGetLayouts({})
        .then(response => {
          this.layouts = response.data;
        })
        .catch(() => {});
    }
  },
  watch: {},
  validations() {
    var fieldsValidations = {};

    Object.keys(this.form).forEach(field => {
      if (this.form[field].validations !== undefined)
        fieldsValidations[field] = this.form[field].validations;
    });

    return {
      campaign: {
        ...fieldsValidations
      }
    };
  }
};
</script>
<style lang="scss" scoped>
.create {
  margin-top: 50px;
  padding-top: $space-base;
  @include workarea-small;
  max-width: 700px;
  padding: $space-large $space-base;

  h1 {
    @include title-small;
    margin-bottom: $space-large;
  }
}

.campaign__fieldgroup {
  margin-bottom: $space-base;
}

.create__actions {
  text-align: right;
}
</style>
