<template> 
  <div class="addCoorg">
    <div class="lang_choice">
      <mks-button size="small" class="lang_btn" type="tertiary" v-on:click="page_lang='fr'">🇫🇷 Français</mks-button>
      <mks-button size="small" class="lang_btn" type="tertiary" v-on:click="page_lang='en'">🇬🇧 English</mks-button>
      <mks-button size="small" class="lang_btn" type="tertiary" v-on:click="page_lang='es'">🇪🇸 Espanol</mks-button>
    </div>
    <mks-heading tag="h2">{{ content[page_lang].heading }}</mks-heading>
    <div class="addCoorg__inner">
      <div class="form">
        <form action="" method="get">
          <fieldset>
            <legend>{{ content[page_lang].legend }}</legend>
            <br>
            <mks-field 
              type="text" 
              name="first_mail" 
              :label="content[page_lang].label_fmail"
              :placeholder="content[page_lang].placeholder_fmail" 
              v-model="first_mail"
              value="">
            </mks-field>
            <mks-field-error 
              v-if="fmailError" 
              :error="content[page_lang].fmailErrorText">    
            </mks-field-error>
            <br>
            <mks-field 
              type="text" 
              name="url_event" 
              :label="content[page_lang].label_url" 
              :placeholder="content[page_lang].placeholder_url" 
              value="" 
              v-model="question">
            </mks-field>
            <mks-field-error 
              v-if="urlError" 
              :error="content[page_lang].urlErrorText">    
            </mks-field-error>
            <br>
            <mks-field 
              type="text" 
              name="mail_coorg" 
              :label="content[page_lang].label_coorgmail" 
              :placeholder="content[page_lang].placeholder_coorgmail" 
              value="" 
              :description="content[page_lang].description_coorgmail"
              v-model="mail_coorg">
            </mks-field>
            <mks-field-error 
              v-if="mailCoorgError" 
              :error="content[page_lang].mailCoorgErrorText">    
            </mks-field-error>
            <br>
            <div class="validateAnswer" v-if="!currentSending">
              <mks-button 
                v-if="!apiAnswer"
                v-on:click="validateButton" 
                class="button" 
                type="positive">
                {{  content[page_lang].submit }}
              </mks-button>
              <mks-field-error
                v-if="errorAPI"
                :error="errorAPIMsg"
              >
              </mks-field-error>
              <p v-if="apiAnswer">
                {{  content[page_lang].added }} {{event.title[lang]}} !
              </p>
          </div>
          <div v-else>
            <mks-loading type="standalone" v-if="currentSending">
            </mks-loading>
          </div>
          </fieldset>
        </form>
      </div>
      <div class="preview" v-if="event">
        <img class="event__image" :src="event.cover[lang].url">
      </div>
  </div>
  </div>
</template>

<script>
import axios from "axios";
import { functions as firebaseFunctions } from "@/modules/firebase";
export default {
  name: "addCoorg",
  data(){
    return{
      clicked: true,
      fmailError: false,
      mailCoorgError: false,
      urlError: false,
      question: '',
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/, //eslint-disable-line
      slug: "", 
      evt_title: "",
      noUrl: false,
      event: null,
      first_mail: "", 
      url_event: "",
      mail_coorg: "",
      result: 0,
      apiAnswer: false,
      lang: "",
      page_lang: "fr",
      errorAPIMsg: "",
      errorAPI: false,
      currentSending: false,
      content: {
        fr: {
          heading: "Ajoute ici le coorganisateur de ton choix", 
          legend: "Rentre ici toutes les infos nécessaires s'il te plaît", 
          label_fmail: "Ton mail Factory", 
          placeholder_fmail: "jacques@makesense.org",
          label_url: "L'url de l'événement auquel tu veux ajouter un co-organisateur :",
          placeholder_url: "https://events.makesense.org/e/mon-event",
          label_coorgmail: "Le mail de ton coorganisateur",
          placeholder_coorgmail: "cousteau@makesense.org",
          description_coorgmail: "Attention, ton coorganisateur doit avoir un compte Factory pour que cela fonctionne !",
          submit: "Valider",
          fmailErrorText: "Une erreur a eu lieu sur ce mail : l'as-tu bien saisi ?",
          urlErrorText: "Une erreur s'est glissée dans cet url !",
          mailCoorgErrorText: "Un souci est apparu sur ce mail, vérifie qu'il soit valide",
          errorAPI1: "Nous n'avons pas trouvé d'événements associés à ton url ! Peux-tu réessayer ?",
          errorAPI2: "Ton mail ne correspond pas au mail de l'organisateur de cet événement... As-tu saisi ton bon mail ?",
          errorAPI3: "Le mail que tu as saisi pour le coorganisateur n'a pas de compte associé sur Factory. As-tu saisi le bon mail ?",
          added: "Le co-organisateur a bien été ajouté à ton événement"
        },
        en: {
          heading: "Add here the co-organizer of your choice",
          legend: "Enter here all the necessary information",
          label_fmail: "Your Factory mail",
          placeholder_fmail: "jacques@makesense.org",
          label_url: "The url of the event to which you want to add a co-organizer:",
          placeholder_url:"https://events.makesense.org/e/my-event",
          label_coorgmail: "Your co-organizer's email",
          placeholder_coorgmail: "cousteau@makesense.org",
          description_coorgmail: "Attention, your co-organizer must have a Factory account for this to work!",
          submit: "Submit",
          fmailErrorText: "There was an error on this email: did you type it correctly?",
          urlErrorText: "An error has appeared in this url!",
          mailCoorgErrorText: "A problem appeared on this mail, check that it is valid.",
          errorAPI1: "We haven't found any events associated with your url! Can you try again?",
          errorAPI2: "Your email doesn't match the email from the organizer of this event... Did you enter your correct email?",
          errorAPI3: "The email you entered for the co-organizer does not have an associated account on Factory. Did you enter the correct email?",
          added: "The co-organizer has been added to your event"
        },
        es: {
          heading: "Añade aquí el coorganizador de tu elección",
          legend: "Ingrese aquí toda la información necesaria",
          label_fmail: "Su correo Factory",
          placeholder_fmail: "jacques@makesense.org",
          label_url: "La url del evento al que quieres añadir un co-organizador:",
          placeholder_url:"https://events.makesense.org/e/mi-evento",
          label_coorgmail: "El correo electrónico de tu co-organizador",
          placeholder_coorgmail: "cousteau@makesense.org",
          description_coorgmail: "Atención, su co-organizador debe tener una cuenta Factory para que esto funcione!",
          submit: "Envíe",
          fmailErrorText: "Había un error en este correo electrónico: ¿lo has escrito bien?",
          urlErrorText: "¡Un error ha aparecido en esta url!",
          mailCoorgErrorText: "Un problema apareció en este correo electrónico, comprueba que es válido.",
          errorAPI1: "¡No hemos encontrado ningún evento asociado con su url! ¿Puedes intentarlo de nuevo?",
          errorAPI2: "Su correo electrónico no coincide con el del organizador de este evento... ¿Has introducido tu correo electrónico correcto?",
          errorAPI3: "El correo electrónico que introdujo para el co-organizador no tiene una cuenta asociada en Factory. ¿Ingresó el correo electrónico correcto?",
          added: "El co-organizador ha sido añadido a su evento"
        }
      }
    }
  },
  methods: {
    validateButton: function() {
      this.result = 0;
      //on vérifie que toutes les entrées sont bonnes
      if (this.first_mail === "" || !this.reg.test(this.first_mail)) {
        this.fmailError = true;
      }
      else{
        this.fmailError = false;
        this.result ++;
      }
      if (this.url_event === ""){
        this.urlError = true;
      }
      else{
        this.result ++;
        this.urlError = false;
      }
      if (this.mail_coorg === "" || !this.reg.test(this.mail_coorg)) {
        this.mailCoorgError = true
      }
      else {
        this.mailCoorgError = ""
        this.result ++
      }

      //on vérifie si tout est bon, et si oui, on envoie la requête à l'API
      if(this.result === 3){
        this.sendToApi()
      }
    },
    sendToApi: function(){
      var resultApi = firebaseFunctions.httpsCallable("eventsAddcoorg");
      this.currentSending = true;

      return resultApi({ fmail: this.first_mail, coorg_mail: this.mail_coorg, evt_id: this.event._id})
        .then((response) => {
          if(response.data === "ADDED"){
            this.apiAnswer = true;
            this.errorAPI = false;
          }
          else {
            this.errorAPI = true;
            if (response.data === "NO_EVENT") {
              this.errorAPIMsg = this.content[this.page_lang].errorAPI1;
            }
            if (response.data === "MAIL_ORGA_DOES_NOT_MATCH") {
              this.errorAPIMsg = this.content[this.page_lang].errorAPI2;
            }
            if (response.data === "COORG_DOES_NOT_EXIST") {
              this.errorAPIMsg = this.content[this.page_lang].errorAPI3;
            }
          }
        this.currentSending = false;
        });
    }
  },
  watch: {
    question: function(){
      this.url_event = this.question
      this.slug=this.question.replace("https://events.makesense.org/e/","")
      axios
      .get(`https://api.factory.makesense.org/v1/events/slug/${this.slug}`)
      .then((res) => {
        this.event = res.data;
        this.evt_title = res.data.title.fr;
        this.lang = res.data.lang;
      })
      .catch((err) => console.log(err));
    }
  }
};
</script>

<style lang="scss" scoped>
.lang_choice {
  .lang_btn {
    margin: $space-m auto;
    margin-right: $space-m;
  }
}
.addCoorg {
  width: 80%;
  margin: $space-m auto;
  .addCoorg__inner {
    margin: $space-m auto;
    display: flex;
    .form {
      flex: 0 0 60%;
    }
  }
  .preview {
    margin: $space-m;
    .event__image {
      max-width:100%;
      justify-content: center;
    }
  }
}
</style>
