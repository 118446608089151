/* eslint-disable no-unused-vars */

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import firebaseApp from "./modules/firebase";
import multiAppMixin from "./mixins/multiApp";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/fr"; // todo add es and en later and switch
import "dayjs/locale/en"; // todo add es and en later and switch
import "dayjs/locale/es"; // todo add es and en later and switch
import DesignSystem from "@makesenseorg/design-system";
import "@makesenseorg/design-system/dist/system.css";
import VueCountdown from "@chenfengyuan/vue-countdown";
import VueClipboard from "vue-clipboard2";
import Toasted from "vue-toasted";
Vue.use(Toasted);

Vue.mixin(multiAppMixin);

dayjs.locale("fr");
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
Vue.prototype.$date = dayjs;

Vue.use(DesignSystem);
// can be changed in the app to something else via `this.$loadTheme('themeName');`
// the theme must be available in the design system
Vue.prototype.$loadTheme("base");

Vue.config.productionTip = false;

// add countdown
Vue.component(VueCountdown.name, VueCountdown);

// add click to copy
Vue.use(VueClipboard);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
