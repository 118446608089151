<template>
  <main class="actions">
    <mks-loading v-if="loading.promotion === 'PENDING'"></mks-loading>
    <template v-else>
      <header class="actions__header">
        <h1>> {{ content[getLang].title }} : {{ promotion.promo.id }}</h1>
      </header>
      <div class="actions__main">
        <div class="actions__menu">
          <mks-field
            class="participants__search"
            name="search"
            type="search"
            v-model="search"
            :placeholder="content[getLang].search_placeholder"
            value=""
          ></mks-field>
          <div class="menu__count">
            {{ getParticipants.length }} participant.e.s
          </div>
          <div class="menu__participants">
            <MenuButton
              v-for="(participant, index) in getParticipants"
              :key="index"
              :label="participant.name"
              :sublabel="participant.phone"
              v-on:click="selectParticipant(participant.id)"
              :selected="selected === participant.id"
              class="menu__participant"
            ></MenuButton>
          </div>
        </div>
        <div class="actions__workspace">
          <div v-if="!selected" class="legal">{{ content[getLang].legal }}</div>
          <div v-else class="editor">
            <div class="editor__close" @click="selected = null">
              <mks-icon type="x-circle"></mks-icon>
            </div>
            <div class="editor__header">
              <div class="left">
                <h2 class="participant__infos">{{ getParticipant.name }}</h2>
                <div class="participant__more">
                  {{ getParticipant.email }} ({{ getParticipant.phone }})
                </div>
              </div>
            </div>
            <div class="editor__add">
              <div class="add__item">
                <mks-field-label>{{
                  content[getLang].type_label
                }}</mks-field-label>
                <select
                  name="actions"
                  id="actions-select"
                  v-model="newAction.type"
                >
                  <option value="">{{
                    content[getLang].action__placeholder
                  }}</option>
                  <option
                    :value="action.id"
                    v-for="action in promotion.actions"
                    :key="action.id"
                    >{{ action.name }}</option
                  >
                </select>
              </div>
              <div class="add__item">
                <mks-field
                  :label="content[getLang].qty_label"
                  class="qty"
                  name="qty"
                  type="number"
                  v-model="newAction.qty"
                  :placeholder="content[getLang].qty_placeholder"
                ></mks-field>
              </div>
              <div class="add__item">
                <mks-field
                  :label="content[getLang].when_label"
                  class="when"
                  name="when"
                  type="text"
                  v-model="newAction.date"
                  :placeholder="content[getLang].when_placeholder"
                  value=""
                ></mks-field>
              </div>

              <div class="add__item">
                <mks-button
                  @click="submitAction"
                  :disabled="newAction.date === '' || newAction.type === ''"
                  :loading="loading.submit === 'PENDING'"
                  >{{ content[getLang].action__add }}</mks-button
                >
              </div>
            </div>
            <div class="editor__add editor__add--full">
                <mks-field
                  type="textarea"
                  v-model="newAction.comment"
                  :label="content[getLang].comment_label"
                  :sub-label="content[getLang].comment_sublabel"
                  placeholder=""
                ></mks-field>
              </div>
            <div class="nostress">{{ content[getLang].no_stress }}</div>
            
            <div class="editor__actions">
              <mks-loading
                type="standalone"
                v-if="usersActions[selected] === undefined"
              ></mks-loading>
              <div v-else>
                <div
                  class="action"
                  v-for="action in usersActions[selected]"
                  :key="action.id"
                >
                  <div class="name">{{ action.name }}</div>
                  <div class="date">
                    {{ $date(action.date).format("DD/MM/YYYY") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </main>
</template>
<script>
import MenuButton from "./components/menuButton.vue";
import lang from "../../mixins/lang.js";
import { functions as firebaseFunctions } from "@/modules/firebase";

export default {
  name: "ProgramsActions",
  components: { MenuButton },
  mixins: [lang],
  data() {
    return {
      id: "",
      usersActions: {},
      name: "",
      search: "",
      selected: null,
      promotion: {},
      form: {},
      newAction: {
        date: "",
        qty: 1,
        type: "",
        registerId: null,
        comment: ""
      },
      loading: {
        promotion: "PENDING",
        submit: "KO"
      },
      content: {
        fr: {
          title: "Suivi des actions de la promotion",
          search_label: "Nom, email ou téléphone du participant",
          search_placeholder: "Chercher...",
          action__add: "Ajouter",
          when_label: "Quand ?",
          when_placeholder: "DD/MM/AAAA",
          type_label: "Quel type d'action ?",
          action__placeholder: "--- Selectionnez un type d'action ---",
          saveSuccess: "Action ajoutée avec succès",
          qty_label: "Quantité",
          qty_placeholder: "1",
          legal:
            "Pour information, les données ne sont pas un tracking exhaustif de chaque participant, les résultats sont communiqués de manière anonyme et permettent d'établir des statistiques et surtout d’améliorer les parcours de mise en action des participants.",
          no_stress:
            "Pas de stress si vous ne notez pas la bonne date pour l’action, c’est pour établir des chronologies ! Notez les quand vous le pouvez au fur et à mesure du programme et des calls de promo !",
          comment_label: "Des infos en plus ?",
          comment_sublabel: "Optionnel",
        },
        en: {
          title: "Actions tracking",
          search_label: "Name, email or phone of the participant",
          search_placeholder: "Search...",
          action__add: "Add",
          when__label: "When ?",
          when_placeholder: "DD/MM/YYYY",
          type_label: "Action type",
          action__placeholder: "--- Pick an action type ---",
          saveSuccess: "Action added successfully",
          qty_label: "Quantity",
          qty_placeholder: "1",
          legal:
            "For information, the data is not an exhaustive tracking of each participant, the results are communicated anonymously and make it possible to establish statistics and especially to improve the courses of action of the participants.",
          no_stress:
            "No stress if you don't jot down the right date for the action, it's to establish timelines! Write them down when you can as the program and promo calls progress!",
          comment_label: "Notes",
          comment_sublabel: "Optional",
        }
      }
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.$date.locale(this.getLang);
    this.setActionDefaultValue();
    this.init();
  },
  computed: {
    getParticipants: function() {
      if (this.search !== "") {
        return this.promotion.participants.filter(obj => {
          return obj.search.indexOf(this.search) >= 0;
        });
      }
      return this.promotion.participants;
    },
    getParticipant: function() {
      return this.promotion.participants.find(obj => {
        return obj.id === this.selected;
      });
    }
  },
  methods: {
    init() {
      this.loading.queue = "PENDING";

      var getPromotion = firebaseFunctions.httpsCallable(
        "programsGetPromotion"
      );

      return getPromotion({ id: this.id })
        .then(response => {
          this.promotion = response.data;
          this.loading.promotion = "OK";
        })
        .catch(error => {
          this.loading.promotion = "ERROR";

          throw error;
        });
    },
    getUserActions() {
      var getUserActions = firebaseFunctions.httpsCallable(
        "programsGetUserActions"
      );

      this.loading.userActions = "PENDING";
      var uid = this.selected;

      return getUserActions({
        id: this.getParticipant.registerId
      })
        .then(response => {
          this.$set(this.usersActions, uid, response.data);
          this.loading.userActions = "OK";
        })
        .catch(err => {
          this.loading.userActions = "ERROR";
          throw err;
        });
    },
    selectParticipant(id) {
      this.selected = id;
    },
    submitAction: function() {
      if (this.loading.submit === "PENDING") return;
      this.loading.submit = "PENDING";

      var saveUserAction = firebaseFunctions.httpsCallable(
        "programsSaveUserAction"
      );

      return saveUserAction({
        ...this.newAction,
        registerId: this.getParticipant.registerId,
        uid: this.selected
      })
        .then(() => {
          this.loading.submit = "OK";

          this.setActionDefaultValue();

          this.$toasted.show(this.content[this.getLang].saveSuccess, {
            type: "success",
            position: "bottom-right",
            duration: 2000
          });

          this.getUserActions();
        })
        .catch(error => {
          this.loading.submit = "ERROR";

          throw error;
        });
    },
    setActionDefaultValue() {
      this.newAction = {
        type: "",
        qty: 1,
        registerId: null,
        date: this.$date(new Date()).format("DD/MM/YYYY")
      };
    }
  },
  watch: {
    selected: function() {
      if (this.selected) this.getUserActions();
    }
  }
};
</script>
<style lang="scss" scoped>
main {
  padding-top: 48px;
}

.actions__main {
  width: 100%;
  display: flex;

  @include breakpoint("small", "max") {
    display: block;
  }

  .actions {
    &__menu,
    &__workspace {
      padding-top: $space-m;
      padding-bottom: $space-m;
      padding-bottom: 100px;
      height: calc(100vh - 101px);

      @include breakpoint("small", "max") {
      }

      overflow: auto;
    }

    &__menu {
      user-select: none;
      padding: $space-s;

      @include breakpoint("small") {
        width: 400px;
        flex-basis: 400px;
        flex-grow: 0;
        flex-shrink: 0;
        padding: $space-m $space-xl;
      }

      .participants__search {
        margin-bottom: $space-s;
      }

      .menu {
        &__participant {
          margin-bottom: $space-xs;
        }

        &__count {
          @include text-small-extrabold;
          margin-bottom: $space-xs;
        }
      }
    }

    &__workspace {
      width: 100%;
      min-height: 300px;
      padding-right: $space-xl;

      @include breakpoint("small", "max") {
        position: fixed;
        top: 0px;
        left: 0px;
        right: 0px;
        z-index: 1000;
        height: 100%;
        bottom: 0px;
        padding: $space-s;
      }

      .editor {
        position: relative;
        border-radius: $border-radius-m;
        border: $border;
        background: $color-neutral-100;
        box-shadow: $box-shadow-l;
        width: 100%;

        &__close {
          position: absolute;
          right: $space-s;
          top: $space-s;
          cursor: pointer;
        }

        @include breakpoint("small", "max") {
          min-height: 100%;
        }

        &__header {
          @include flex;
          border-bottom: 1px solid $color-neutral-90;
          padding: $space-s;
          align-items: center;

          @include breakpoint("small") {
            padding: $space-l;
          }

          h2 {
            @include title-small;
          }

          .participant__more {
            @include text-large;
          }

          .left {
            width: 100%;
          }
          .right {
            flex: 0 0 auto;
          }
        }

        .nostress {
          padding-top: 0px;
          padding: $space-s;
          @include text-small;
          border-bottom: 1px solid $color-neutral-90;

          @include breakpoint("small") {
            padding: $space-l;
            padding-top: 0px;
            max-width: 700px;
          }
        }

        &__add {
          padding: $space-s;
          padding-bottom: $space-xs;

          @include breakpoint("small") {
            padding: $space-l;
            padding-bottom: $space-xs;
          }

          @include breakpoint("small") {
            @include flex;
            align-items: flex-end;
            flex-wrap: nowrap;
          }

          &--full {
            padding-top: 0;
            display: block;
          }

          .add__item {
            margin-bottom: $space-s;
            @include breakpoint("small") {
              width: 30%;
              margin-right: $space-s;
              margin-bottom: 0px;
            }

            &--full {
              display: block;
              width: 100%;
              margin-right: 0;
            }
          }

          select {
            @include field;
            padding: $space-s;
            border: $border;
            @include border-radius;
            cursor: pointer;
          }
        }

        &__actions {
          background: $color-neutral-95;
          padding: $space-s;

          @include breakpoint("small") {
            padding: $space-l;
          }

          .action {
            padding: $space-base;
            background: $color-neutral-100;
            @include elevated;
            @include border-radius;
            margin-bottom: $space-base;

            .name {
              @include text-body-black;
            }

            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
      }
    }
  }
}

header {
  padding: $space-s;
  @include text-large-black;
}

.legal {
  height: 100%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  @include text-body-black;

  padding: $space-s;

  @include breakpoint("small") {
    padding: $space-l;
  }
}
</style>
