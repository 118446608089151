<template>
  <div class="campaign">
    <mks-loading v-if="loading !== 'OK'"></mks-loading>
    <div v-else>
      <header class="campaign__header">
        <h1>Tracking - {{ campaign.name }} - Promo {{currentPromo['PROMO']}}</h1>
        <select v-model="trackPeriod">
          <option value="all">Tout</option>
          <option value="0.5">12 heures</option>
          <option value="1">1 jour</option>
          <option value="3">3 jours</option>
          <option value="7">7 jours</option>
          <option value="15">15 jours</option>
          <option value="30">30 jours</option>
        </select>
      </header>

      <main class="templates">
        <nav class="templates__menu">
          <div
            v-for="promo in campaign.promos"
            :key="promo.id"
            class="menu__item"
            :class="{
              '--active': promo.id === currentPromo.id
            }"
            v-on:click="currentPromoId = promo.id"
          >
            <div class="item__name">
              <p>{{ promo['PROMO'] }} - {{$date(promo['Start date']).format('DD/MM/YYYY')}}</p>
              <p>
                {{promo.metrics.total}} -
                {{Math.round(promo.metrics.delivered / promo.metrics.total * 100)}}% -
                {{Math.round(promo.metrics.opened / promo.metrics.total * 100)}}% -
                {{Math.round(promo.metrics.clicked / promo.metrics.total * 100)}}%
              </p>
            </div>
          </div>
        </nav>
        <div class="templates__workspace" :key="currentPromo.id + ':' + trackPeriod">
          <div
            class="template__fieldgroup"
            v-for="(mail) in currentPromo.mails"
            :key="mail.id"
            @click="toggle(mail.id)"
          >
            <div
              class="templates__edition">
              <div>
                <h3>{{mail.Name}} - {{$date(mail.When).format('DD/MM/YYYY')}}</h3>
                <p v-if="mail.metrics">
                  <strong>Destinataires</strong> : {{mail.Recipients}},
                  <strong>Envois</strong>: {{Math.round(mail.metrics.delivered / mail.Recipients * 100)}}%,
                  <strong>Ouverture</strong>: {{Math.round(mail.metrics.opened / mail.Recipients * 100)}}%,
                  <strong>Click</strong>: {{Math.round(mail.metrics.clicked / mail.Recipients * 100)}}%
                </p>
              </div>
              <ul v-if="expandedMails.includes(mail.id)" @click.capture.stop>
                <li v-for="user in mail.users" :key="user.mail" class="recipient" :class="{
                  '--danger': !user.delivered,
                  '--warning': user.delivered && !user.opened,
                  '--yellow': user.opened && !user.clicked,
                  '--success': user.clicked
                }">
                  {{user.mail}}  {{user.clicked ? 'Cliqué' : (user.opened ? 'Ouvert' : (user.delivered ? 'Envoyé' : ''))}}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import { functions as firebaseFunctions } from "@/modules/firebase";
export default {
  data: function () {
    return {
      loading: 'PENDING',
      campaignData: null,
      currentPromoId: null,
      trackPeriod: 'all',
      expandedMails: []
    };
  },
  created() {
    var getCampaignTracking = firebaseFunctions.httpsCallable(
      "programsGetCampaignTracking"
    );
    return getCampaignTracking({ id: this.$route.params.id })
      .then((response) => {
        this.campaignData = response.data.campaign;
        this.currentPromoId = this.campaign.promos[0].id;
        this.loading = 'OK';
      })
  },
  computed: {
    campaign() {
      const period = this.trackPeriod === 'all' ? null : parseFloat(this.trackPeriod);
      return {
        ...this.campaignData,
        promos: this.campaignData.promos.map((promo) => {
          const promoMetrics = {
            total: 0,
            delivered: 0,
            opened: 0,
            clicked: 0
          };
          return {
            ...promo,
            mails: promo.mails.map((mail) => {
              const mailMetrics = {
                delivered: 0,
                opened: 0,
                clicked: 0
              };
              const mailUsers = [];
              const periodMaxDate = period ? this.$date(mail.When).add(period * 24, 'hours') : this.$date();
              mail['Mail Delivery IDs'].split('\n').forEach((line) => {
                const [userMail, deliveryId, delivered, opened, clicked] = line.split(':');
                let score = 0;
                promoMetrics.total++;
                if (deliveryId && delivered && this.$date(delivered * 1000) <= periodMaxDate) {
                  promoMetrics.delivered++;
                  mailMetrics.delivered++;
                  score += 1;
                }
                if (opened && this.$date(opened * 1000) <= periodMaxDate) {
                  promoMetrics.opened++;
                  mailMetrics.opened++;
                  score += 10;
                }
                if (clicked && this.$date(clicked * 1000) <= periodMaxDate) {
                  promoMetrics.clicked++;
                  mailMetrics.clicked++;
                  score += 100;
                }

                mailUsers.push({mail: userMail, delivered, opened, clicked, score});
              });
              mailUsers.sort((a, b) => b.score - a.score);
              return {
                ...mail,
                metrics: mailMetrics,
                users: mailUsers
              }
            }),
            metrics: promoMetrics
          }
        })
      };
    },
    currentPromo() {
      return this.campaign.promos.find((promo) => promo.id === this.currentPromoId);
    }
  },
  methods: {
    toggle(mailId) {
      const io = this.expandedMails.indexOf(mailId);
      if (io === -1) {
        this.expandedMails.push(mailId);
      }
      else {
        this.expandedMails.splice(io, 1);
      }
    }
  },
  watch: {
    currentPromoId() {
      this.expandedMails = [];
    },
    trackPeriod() {
      this.expandedMails = [];
    }
  }
}

</script>
<style lang="scss" scoped>
.campaign {
  padding-top: 50px;

  &__header {
    padding: $space-s;

    h1 {
      @include text-body-black;
      .edit {
        margin-left: $space-xxs;
        @include text-small;
        font-weight: $font-weight-bold;
        color: $text-color-link;
        @include cursor-pointer;
      }
    }
  }
}
.templates {
  width: 100%;

  display: flex;
  flex: 1 0 auto;
  &__menu,
  &__workspace {
    padding-top: $space-m;
    padding-bottom: $space-m;
    padding-bottom: 100px;
  }

  &__menu {
    width: 226px;
    user-select: none;
    padding: $space-m $space-s;
    padding-bottom: 100px;

    .menu {
      &__item {
        padding: 10px;
        cursor: pointer;
        border-radius: $border-radius-m;
        font-size: $font-size-s;
        text-overflow: ellipsis;
        word-wrap: break-word;
        font-weight: $font-weight-extrabold;

        &:hover {
          background: $color-neutral-95;
          opacity: 1;
        }

        &.--draft {
          opacity: 0.5;

          &:hover {
            opacity: 1;
          }
        }

        &.--active,
        &.--active.--draft {
          background: $color-neutral-90;
          font-weight: $font-weight-black;
          opacity: 1;

          .item__day {
            background-color: #fff;
          }
        }

        &.--errors {
          .item__name {
            color: $color-danger-active;
          }
        }

        &.new_template {
          background-color: $color-success;
          color: #fff;
          text-align: center;
          margin-top: $space-s;
        }

        .item {
          margin-bottom: 10px;
          &__day {
            border-radius: $border-radius-s;
            margin-right: 0.4em;
            padding: 0.1em 0.2em;
            border: 1px solid $border-color-base;
          }
        }
      }
    }
  }

  &__workspace {
    flex: 1;
    overflow-x: hidden;
    position: relative;
    padding: 20px;
    padding-bottom: 100px;
  }

  &__edition {
    border-radius: $border-radius-m;
    border: $border;
    background: $color-neutral-100;
    box-shadow: $box-shadow-l;
    padding: $space-s;

    .edition__actions {
      > * {
        margin-right: $space-s;
      }
    }
  }

  &__footer {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 100;
    background: #fff;
    padding: $space-s;
    @include flex;
    align-items: center;
    border-top: 1px solid $border-color-base;

    .footer {
      &__actions {
        @include flex;

        flex-grow: 2;
        justify-content: flex-end;
      }

      &__infos {
        .infos {
          &__errors {
            @include text-body-black;
            color: $color-danger;
          }
        }
      }
    }
    .archive__campaign,
    .duplicate__campaign {
      margin-right: $space-s;
    }
  }

  .template {
    &__fieldgroup {
      margin-bottom: $space-m;
      cursor: pointer;
      strong {
        font-weight: bold;
      }
    }

    &__emaileditor {
      height: 700px;
    }
  }
}

.popup__email {
  @include flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1000;
  background-color: #fff;

  .email {
    &__main,
    &__header {
    }

    &__header {
      @include flex;
      align-items: center;
      border-bottom: $border;

      flex-grow: 0;
      flex-shrink: 0;
      padding: $space-s;

      .header {
        &__left {
          @include text-large-black;
        }

        &__right {
          @include flex;
          align-items: center;
          flex-grow: 2;
          justify-content: flex-end;

          .action {
            margin-left: $space-s;
            cursor: pointer;
          }
        }
      }
    }

    &__loading {
      position: absolute;
      height: 100%;
      z-index: 100;
      background-color: #fff;
      left: 0px;
      top: 0px;
      bottom: 0px;
      right: 0px;
      @include flex;
      align-items: center;
      justify-content: center;
    }

    &__main {
      flex-grow: 2;
      height: calc(100vh - 84px);
    }

    &__editor {
      height: 100%;

      .blockbuilder-branding {
        display: none !important;
      }
    }
  }
}

.recipient {
  margin-top: $space-s;

  &:before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
  }
  &.--danger:before {
    background-color: $color-danger;
  }
  &.--warning:before {
    background-color: $color-warning;
  }
  &.--yellow:before {
    background-color: $color-yellow;
  }
  &.--success:before {
    background-color: $color-success;
  }
}
</style>
