export default {
  methods: {
    getCurrentApp: function() {
      if (window.location.hostname === "app.tv.makesense.org")
        return "makesenseTV";
      else if (
        window.location.hostname === "programs.makesense.org" ||
        window.location.hostname === "localhost"
      )
        return "programs";
      else if (window.location.hostname === "analytics.makesense.org")
        return "analytics";
      else {
        window.analytics.load(process.env.VUE_APP_SEGMENT_EVENTS);
        return "default";
      }
    },
  },
};
