<template>
  <main class="edit">
    <mks-loading v-if="loading.layout === 'PENDING'" type="component"></mks-loading>
    <div class="edit__form" v-else-if="loading.layout === 'OK'">
      <h1>{{ content[getLang].editTitle }}</h1>
      <div class="fieldgroup" v-for="(field, index) in form" :key="index">
        <mks-field-label>{{ field.name[getLang] }}</mks-field-label>
        <mks-field
          class="fieldgroup__field"
          :name="index"
          :id="index"
          :type="field.type"
          :placeholder="
                  field.placeholder ? field.placeholder[getLang] : null
                "
          :description="
                  field.description ? field.description[getLang] : null
                "
          :error="
                  $v.layout[index] !== undefined && touch
                    ? $v.layout[index].$invalid
                    : false
                "
          :options="field.options"
          :min="field.min"
          :max="field.max"
          v-model="layout[index]"
          :editorToolbar="field.editorToolbar"
        />
      </div>
      <div class="fieldgroup">
        <mks-button class="editorButton" tag="button" @click="editor = true">
          {{
          content[getLang].editLayoutEditorLabel
          }}
        </mks-button>
      </div>
      <div class="edit__actions">
        <mks-button
          tag="button"
          type="positive"
          @click="onEdit"
          :loading="loading.edit === 'PENDING'"
        >
          {{
          content[getLang].editLabel
          }}
          <template
            v-slot:loading
          >{{ content[getLang].editLabelLoading }}</template>
        </mks-button>
      </div>
      <transition name="slide-fade">
        <FullModal v-show="editor">
          <template v-slot:header__left>{{ content[getLang].editorTitle }}</template>
          <template v-slot:header__right>
            <mks-button @click="onClose" tag="button" type="neutral">{{ content[getLang].close }}</mks-button>
          </template>
          <EmailEditor
            ref="editor"
            v-on:load="editorLoaded"
            class="editor"
            :options="emailEditorOptions"
          />
        </FullModal>
      </transition>
    </div>
  </main>
</template>
<script>
import FullModal from "./components/fullModal.vue";
import { functions as firebaseFunctions } from "@/modules/firebase";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import lang from "../../mixins/lang.js";

export default {
  name: "LayoutEdit",
  components: { FullModal },
  mixins: [validationMixin, lang],
  data() {
    return {
      id: "",
      editor: false,
      touch: false,
      loading: {
        layout: "PENDING",
        edit: "KO"
      },
      content: {
        fr: {
          editLabel: "Enregistrer les modifications",
          editTitle: "Edition du layout",
          editFormErrors: "Le formulaire contient des erreurs",
          editFormSuccess: "Mise à jour réussie",
          editLayoutEditorLabel: "Editer le template",
          editLayoutEditor: "Editer",
          close: "Fermer",
          editorTitle: "Edition du template",
          editLabelLoading: "Enregistrement..."
        },
        en: {
          editLabel: "Edit layout",
          editTitle: "Update layout",
          editFormErrors: "The form contains errors",
          editLayoutEditorLabel: "Template edition",
          editFormSuccess: "Update successful",
          editLayoutEditor: "Edit",
          close: "Close",
          editorTitle: "Template edition",
          editLabelLoading: "Saving..."
        }
      },
      layout: {
        name: "",
        description: "",
        topic: "",
        editor: ""
      },
      form: {
        name: {
          type: "text",
          name: { fr: "Nom du layout", en: "Layout name" },
          placeholder: { fr: "Mon layout #1...", en: "My layout #1..." },
          required: true,
          validations: {
            required
          }
        },
        description: {
          type: "textarea",
          name: {
            fr: "Description du layout",
            en: "Layout description"
          },
          placeholder: {
            fr: "Ce layout est ...",
            en: "This layout is for..."
          },
          validations: {
            required
          }
        },
        topic: {
          type: "select",
          name: { fr: "Thématique associée", en: "Topics" },
          options: {
            Seniors: {
              label: "Seniors",
              value: "Seniors"
            },
            "Primo-arrivants": {
              label: "Primo-arrivants",
              value: "Primo-arrivants"
            }
          },
          validations: {
            required
          }
        }
      }
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getLayout(this.id);
  },
  mounted() {},
  computed: {
    emailEditorOptions: function() {
      return {
        projectId: 5572,
        locale: this.getLang === "fr" ? "fr-FR" : "en-US",
        mergeTags: {}
      };
    }
  },
  methods: {
    getLayout: function() {
      this.loading.layout = "PENDING";

      var getLayout = firebaseFunctions.httpsCallable("programsGetLayout");

      return getLayout({ id: this.id })
        .then(response => {
          this.layout = response.data;
          this.$v.$touch();
          this.loading.layout = "OK";
          this.loadEditorContent();
        })
        .catch(error => {
          this.loading.layout = "ERROR";

          throw error;
        });
    },
    onEdit: function() {
      this.loading.edit = "PENDING";
      var saveLayout = firebaseFunctions.httpsCallable("programsSaveLayout");

      return saveLayout({
        ...this.layout,
        editor: JSON.stringify(this.layout.editor),
        locale: this.getLang
      })
        .then(() => {
          this.loading.edit = "OK";

          this.$toasted.show(this.content[this.getLang].editFormSuccess, {
            position: "bottom-right",
            duration: 2000,
            type: "success"
          });
        })
        .catch(() => {
          this.loading.edit = "ERROR";

          this.$toasted.show(this.content[this.getLang].editFormErrors, {
            position: "bottom-right",
            duration: 2000,
            type: "error"
          });
        });
    },
    editorLoaded() {},
    onClose: function() {
      this.editor = false;

      this.$refs.editor.saveDesign(design => {
        this.layout.editor = design;
      });
    },
    loadEditorContent: function() {
      this.$nextTick(() => {
        setTimeout(() => {
          var content = {
            counters: {
              u_column: 0,
              u_row: 0,
              u_content_button: 0,
              u_content_image: 0,
              u_content_text: 0
            },
            body: {
              rows: [],
              values: {
                backgroundColor: "#ffffff",
                backgroundImage: {
                  url: "",
                  fullWidth: true,
                  repeat: false,
                  center: true,
                  cover: false
                },
                contentWidth: "600px",
                fontFamily: {
                  label: "Raleway",
                  value: "'Raleway',sans-serif",
                  url:
                    "https://fonts.googleapis.com/css?family=Raleway:400,700",
                  defaultFont: true
                },
                linkStyle: {
                  body: true,
                  linkColor: "#0000ee",
                  linkHoverColor: "#0000ee",
                  linkUnderline: true,
                  linkHoverUnderline: true
                },
                _meta: { htmlID: "u_body", htmlClassNames: "u_body" }
              }
            }
          };

          if (
            this.layout.editor !== undefined &&
            this.layout.editor &&
            this.layout.editor !== ""
          )
            content = JSON.parse(this.layout.editor);

          this.$refs.editor.loadDesign(content);
        }, 1000);
      });
    }
  },
  watch: {},
  validations() {
    var fieldsValidations = {};

    Object.keys(this.form).forEach(field => {
      if (this.form[field].validations !== undefined)
        fieldsValidations[field] = this.form[field].validations;
    });

    return {
      layout: {
        ...fieldsValidations
      }
    };
  }
};
</script>
<style lang="scss" scoped>
.edit {
  margin-top: 50px;
  padding-top: $space-base;
  @include workarea-small;
  max-width: 700px;
  padding: $space-large $space-base;

  h1 {
    @include title-small;
    margin-bottom: $space-large;
  }
}

.editor {
  height: 100%;
}

.fieldgroup {
  margin-bottom: $space-base;
}

.edit__actions {
  padding-top: $space-base;
  margin-top: $space-base;
  border-top: 1px solid $border-color-soft;
  text-align: right;
}

.editorButton {
  width: 100%;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(100vh);
}
</style>
