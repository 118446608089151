<template>
  <main class="actions">
    <mks-loading v-if="loading.promotion === 'PENDING'"></mks-loading>
    <template v-else>
      <header class="actions__header">
        <h1>> {{ content[getLang].title }} : {{ promotion.promo.id }}</h1>
      </header>
      <div class="queue__main">
        <div class="queue__menu">
          <div class="tools">
            <a
              target="_blank"
              :href="
                'https://form.typeform.com/to/pBYyUJLC?typeform-medium=embed-snippet#promo=' +
                  this.id
              "
              ><mks-button type="neutral" size="full">{{
                content[getLang].newNote
              }}</mks-button></a
            >
          </div>
          <Timeline
            :items="getTimelineItems"
            @select="(id) => (selected = id)"
          ></Timeline>
        </div>
        <div class="queue__workspace" v-if="selected">
          <div class="editor">
            <div class="editor__header">
              <div class="left">
                <h2 class="editor__subject">
                  {{ momentsObject[selected].title }}
                </h2>
                <p class="editor__date">
                  {{
                    $date(momentsObject[selected].date).format(
                      "dddd D MMMM YYYY"
                    )
                  }}
                </p>
              </div>
            </div>
            <div
              class="editor__content"
              v-html="momentsObject[selected].text"
            ></div>
            <div class="editor__link" v-if="momentsObject[selected].link">
              <a target="_blank" :href="momentsObject[selected].link"
                ><mks-button>{{ content[getLang].show }}</mks-button></a
              >
            </div>
          </div>
        </div>
      </div>
    </template>
  </main>
</template>
<script>
import lang from "../../mixins/lang.js";
import { functions as firebaseFunctions } from "@/modules/firebase";
import Timeline from "./components/timeline.vue";

export default {
  name: "ProgramsActions",
  components: { Timeline },
  mixins: [lang],
  data() {
    return {
      id: "",
      promotion: {},
      selected: null,
      moments: [],
      momentsObject: {},
      loading: {
        promotion: "PENDING",
        moments: "PENDING",
      },
      content: {
        fr: {
          title: "Les moments forts de la promotion",
          show: "Voir",
          newNote: "Créer une note",
          type: {
            MAIL: "Email",
            EVENEMENT: "Évenement",
            WHATSAPP: "WhatsApp",
            NOTE: "Note",
          },
        },
        en: {
          title: "The highlights of the promotion",
          show: "Show",
          newNote: "Créer une note",
          type: {
            MAIL: "Mail",
            EVENEMENT: "Event",
            WHATSAPP: "WhatsApp",
            NOTE: "Note",
          },
        },
      },
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.$date.locale(this.getLang);
    this.init();
  },
  mounted() {},
  computed: {
    getTimelineItems: function() {
      var items = [];
      var icons = {
        MAIL: "mail",
        EVENEMENT: "calendar",
        NOTE: "book-open",
        SEND: "send",
      };

      this.moments.forEach((moment) => {
        items.push({
          id: moment.id,
          title: this.$date(moment.date).format("dddd D MMMM YYYY"),
          type: "date",
          icon: icons[moment.type],
          content: moment.title,
          meta: this.content[this.getLang].type[moment.type],
          date: {
            raw: moment.date,
            day: this.$date(moment.date).format("D"),
            full: this.$date(moment.date).format("YYYYMD"),
            formated: this.$date(moment.date).format("dddd D MMMM YYYY"),
          },
          style: "primary",
        });
      });

      return items;
    },
  },
  methods: {
    init() {
      this.loading.promotion = "PENDING";

      var getPromotion = firebaseFunctions.httpsCallable(
        "programsGetPromotion"
      );

      return getPromotion({ id: this.id })
        .then((response) => {
          this.promotion = response.data;
          this.loading.promotion = "OK";

          var getMoments = firebaseFunctions.httpsCallable(
            "programsGetMoments"
          );
          return getMoments({ id: this.id });
        })
        .then((response) => {
          this.moments = response.data.moments;
          this.moments.forEach((moment) => {
            this.momentsObject[moment.id] = {
              ...moment,
              text: moment.text,
            };
          });
          this.loading.moments = "OK";
        })
        .catch((error) => {
          this.loading.promotion = "ERROR";

          throw error;
        });
    },
  },
  watch: {
    selected: function() {},
  },
};
</script>
<style lang="scss" scoped>
main {
  padding-top: 48px;
}

.queue__main {
  width: 100%;
  display: flex;

  .queue {
    &__menu,
    &__workspace {
      padding-top: $space-m;
      padding-bottom: $space-m;
      padding-bottom: 100px;
      height: calc(100vh - 101px);

      overflow: scroll;
    }

    &__menu {
      width: 400px;
      flex-basis: 400px;
      flex-grow: 0;
      flex-shrink: 0;
      user-select: none;
      padding: $space-m $space-xl;
    }

    &__workspace {
      width: 100%;
      min-height: 300px;
      padding-right: $space-xl;

      .editor {
        border-radius: $border-radius-m;
        border: $border;
        background: $color-neutral-100;
        box-shadow: $box-shadow-l;
        padding: $space-s;
        width: 100%;

        &__content {
          @include text-body;
          max-width: 700px;
          white-space: pre-wrap;
          margin-top: $space-base;
          margin-bottom: $space-base;
        }

        &__header {
          @include flex;
          border-bottom: 1px solid $color-neutral-90;
          padding-bottom: $space-xs;
          align-items: center;

          .left {
            width: 100%;
          }
          .right {
            flex: 0 0 auto;
          }
        }

        &__subject {
          @include text-large-black;
        }
      }
    }
  }

  .tools {
    margin-bottom: $space-l;
    padding-bottom: $space-l;
    border-bottom: $border;
  }
}

header {
  padding: $space-s;
  @include text-large-black;
}
</style>
