import Vue from "vue";
import Router from "vue-router";
import multiApp from "./mixins/multiApp";
import feedbackThanks from "./views/events/feedbackThanks.vue";
import addCoorg from "./views/events/addCoorg.vue";
import MakesenseTv from "./views/makesenseTV/index.vue";
import CheckIn from "./views/checkin.vue";
import MakesenseTvHome from "./views/makesenseTV/home.vue";

import Programs from "./views/programs/index.vue";
import ProgramsHome from "./views/programs/home.vue";
import Campaign from "./views/programs/campaign.vue";
import CampaignCreate from "./views/programs/create.vue";
import CampaignTracking from "./views/programs/tracking.vue";
import Preview from "./views/programs/preview.vue";
import ProgramsEditLayout from "./views/programs/editLayout.vue";
import ProgramsGetQueue from "./views/programs/queue.vue";
import ProgramsTimeline from "./views/programs/timeline.vue";
import ProgramsActions from "./views/programs/actions.vue";

import Analytics from "./views/analytics/index.vue";

import TrainingNext from "./views/training/next.vue";

Vue.use(Router);

var router = null;

if (multiApp.methods.getCurrentApp() === "makesenseTV") {
  router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
      {
        path: "/",
        name: "makesenseTvApp",
        component: MakesenseTv,
        redirect: { name: "makesenseTvHome" },
        children: [
          {
            path: "/home",
            name: "makesenseTvHome",
            component: MakesenseTvHome,
          },
          {
            path: "/checkin",
            name: "checkin",
            component: CheckIn,
          },
        ],
      },
    ],
  });
} else if (multiApp.methods.getCurrentApp() === "programs") {
  router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
      {
        path: "/",
        name: "programsApp",
        component: Programs,
        redirect: { name: "programsHome" },
        children: [
          {
            path: "/home",
            name: "programsHome",
            component: ProgramsHome,
          },
          {
            path: "/layout/:id",
            name: "programsEditCreate",
            component: ProgramsEditLayout,
          },
          {
            path: "/campaign/create",
            name: "campaignCreate",
            component: CampaignCreate,
          },
          {
            path: "/campaign/:id",
            name: "campaign",
            component: Campaign,
          },
          {
            path: "/campaign/tracking/:id",
            name: "campaignTracking",
            component: CampaignTracking,
          },
          {
            path: "/preview/:id",
            name: "preview",
            component: Preview,
          },
          {
            path: "/timeline/:id",
            name: "timeline",
            component: ProgramsTimeline,
          },
          {
            path: "/queue/:id",
            name: "programsQueue",
            component: ProgramsGetQueue,
          },
          {
            path: "/actions/:id",
            name: "programsActions",
            component: ProgramsActions,
          },
        ],
      },
    ],
  });
} else if (multiApp.methods.getCurrentApp() === "analytics") {
  router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
      {
        path: "/",
        name: "analyticsApp",
        component: Analytics,
      },
    ],
  });
} else {
  router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
      {
        path: "/events/feedback-thanks",
        name: "feedbackThanks",
        component: feedbackThanks,
      },
      {
        path: "/events/add-coorg",
        name: "addCoorg",
        component: addCoorg,
      },
      {
        path: "/makesense-tv",
        name: "makesenseTvApp",
        component: MakesenseTv,
        redirect: { name: "makesenseTvHome" },
        children: [
          {
            path: "checkin",
            name: "checkin-tv",
            component: CheckIn,
          },
        ],
      },
      {
        path: "/makesense-tv",
        name: "makesenseTvHome",
        component: MakesenseTvHome,
      },
      {
        path: "/training/:program/next",
        name: "trainingNext",
        component: TrainingNext,
      },
    ],
  });
}

export default router;
