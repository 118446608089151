<template>
  <div class="analytics">
    <mks-loading></mks-loading>
  </div>
</template>
<script>
import firebaseApp from "../../modules/firebase";
import { firebase } from "../../modules/firebase";
import queryString from "query-string";

let psl = require("psl");

export default {
  components: {},
  methods: {
    extractHostname(url) {
      var hostname;
      //find & remove protocol (http, ftp, etc.) and get hostname

      if (url.indexOf("//") > -1) {
        hostname = url.split("/")[2];
      } else {
        hostname = url.split("/")[0];
      }

      //find & remove port number
      hostname = hostname.split(":")[0];
      //find & remove "?"
      hostname = hostname.split("?")[0];

      return hostname;
    }
  },
  async created() {
    var url = null;

    if (this.$route.query.url !== undefined) url = this.$route.query.url;
    else if (this.$route.query.id !== undefined) {
      url = await firebaseApp
        .firestore()
        .collection("analyticsMapping")
        .doc(String(this.$route.query.id))
        .get()
        .then(doc => {
          var site = doc.data();
          if (this.$route.query.redirectionparams)
            return (
              site.url +
              (site.url.indexOf("?") === -1 ? "?" : "&") +
              queryString.stringify(this.$route.query)
            );

          return site.url;
        })
        .catch(() => {
          return null;
        });
    }

    if (url) {
      var domain = psl.get(this.extractHostname(url));
      var data = {};

      var urlToSave = "";
      if (url.indexOf("?") !== -1)
        urlToSave = url.substring(0, url.indexOf("?"));
      else urlToSave = url;

      data["redirect"] = firebase.firestore.FieldValue.increment(1);
      data[
        urlToSave
          .replace("https://", "")
          .replace("http://", "")
          .replace(/[^a-zA-Z0-9]/g, "_")
      ] = firebase.firestore.FieldValue.increment(1);

      firebaseApp
        .firestore()
        .collection("analytics")
        .doc(domain)
        .update(data)
        .catch(() => {
          return firebaseApp
            .firestore()
            .collection("analytics")
            .doc(domain)
            .set(data);
        })
        .finally(() => {
          if (url !== undefined) window.location.href = url;
          else window.location.href = "https://www.makesense.org";
        });
    } else {
      window.location.href = "https://www.makesense.org";
    }
  }
};
</script>
<style lang="scss" scoped></style>
