<template>
  <div class="programs">
    <header>
      <router-link :to="{ name: 'programsHome', params: {  }}">
        <Logotype class="header__item"></Logotype>
      </router-link>
      <div class="header__item lang">
        <a href="?lang=fr">Français</a>
        <a href="?lang=en">English</a>
      </div>
    </header>
    <router-view />
  </div>
</template>
<script>
import Logotype from "./components/logotype.vue";
export default {
  components: {
    Logotype
  },
  created() {
    this.$loadTheme("base");
  }
};
</script>
<style lang="scss" scoped>
header {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 101;
  background-color: #fff;
  border-bottom: 1px solid $border-color-base;

  display: flex;

  .header__item {
    flex-grow: 1;

    &.lang {
      text-align: right;
      @include text-body-black;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      a {
        margin-right: 20px;
      }
    }
  }
}
</style>
