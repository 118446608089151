<template>
  <div
    class="menuButton"
    :class="{ 'menuButton--selected': selected }"
    @click="$emit('click')"
  >
    <div class="menuButton__content">
      <div class="menuButton__label">{{ label }}</div>
      <div class="menuButton__sublabel" v-if="sublabel">{{ sublabel }}</div>
    </div>

    <mks-icon type="arrow-right-circle" v-show="selected"></mks-icon>
  </div>
</template>

<script>
export default {
  name: "MenuButton",
  props: {
    label: {
      required: true,
      type: String,
    },
    sublabel: {
      required: false,
      type: String,
    },
    selected: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.menuButton {
  @include border-radius;
  padding: $space-s;
  border: $border;
  @include cursor-pointer;
  @include flex;
  align-items: center;

  &:hover {
    border-color: $border-color-active;
  }

  &--selected {
    border-color: $color-neutral-90;
    background-color: $color-neutral-90;
  }

  &__content {
    margin-right: $space-s;
    flex: 1 1 auto;
  }

  &__label {
    @include text-body-black;
  }
}
</style>
