<template>
  <main class="preview">
    <mks-loading v-if="loading.email === 'PENDING'"></mks-loading>
    <div v-else>
      <iframe class="iframe" :srcdoc="email.content"></iframe>
    </div>
  </main>
</template>
<script>
import lang from "../../mixins/lang.js";
import { functions as firebaseFunctions } from "@/modules/firebase";

export default {
  name: "ProgramsHome",
  components: {},
  mixins: [lang],
  data() {
    return {
      id: "",
      email: "",
      loading: {
        email: "PENDING"
      },
      content: {
        fr: {},
        en: {}
      }
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getEmail();
  },
  computed: {
    lang() {
      return "fr";
    }
  },
  methods: {
    getEmail() {
      this.loading.email = "PENDING";

      var getSentEmail = firebaseFunctions.httpsCallable(
        "programsGetSentEmail"
      );

      return getSentEmail({ id: this.id })
        .then(response => {
          this.email = response.data.email;

          this.$nextTick(function() {
            window.dispatchEvent(new Event("resize"));
          });

          if (response.data.success) this.loading.email = "OK";
          else this.loading.email = "ERROR";
        })
        .catch(error => {
          this.loading.email = "ERROR";

          throw error;
        });
    }
  },
  watch: {}
};
</script>
<style lang="scss" scoped>
.iframe {
  width: 100%;
  padding-top: 48px;
  height: 100vh;
}
</style>
