<template>
  <div class="logotype">_programs</div>
</template>

<script>
export default {
  name: "Logotype",
};
</script>

<style lang="scss" scoped>
.logotype {
  padding: 10px;
  font-size: 1.3em;
  font-weight: $font-weight-extrablack;
}
</style>
