<template>
  <div class="page">
    <header class="page__header">
      <img
        v-if="program === 'reaction'"
        src="https://s3.amazonaws.com/unroll-images-production/projects%2F0%2F1602497102111-Capture+d%E2%80%99e%CC%81cran+2020-10-07+a%CC%80+20.02.39.png"
        alt="Ré-action"
        class="header__banner"
      />
      <img
        v-else-if="program === 'labreche'"
        src="@/assets/images/labreche/labreche.jpg"
        alt="La Brèche"
        class="header__banner"
      />
      <img
        v-else-if="program === 'sprint'"
        src="@/assets/images/sprint/sprint-banner.png"
        alt="Sprint"
        class="header__banner"
      />
      <div v-else class="fallback"></div>
    </header>
    <div v-if="hookStatus === 'success'" class="page__content">
      <div class="section section--congrats">
        <div class="heading__wrapper">
          <mks-heading tag="h1">Félicitations<span v-if="name"> {{name}}</span>,</mks-heading>
          <mks-heading tag="h2"
            >pour avoir complété le module {{ niveau }} 👏</mks-heading
          >
        </div>
        <mks-text tag="p" align="center" color="light"
          >Le
          <mks-text tag="span" weight="bold"
            >module {{ niveau + 1 }} est déjà en route vers ta boîte
            mail</mks-text
          >.</mks-text
        >
        <mks-text tag="p" align="center" v-if="progress" color="light"
          >Tu as
          <mks-text tag="span" weight="bold">terminé {{ progress }}%</mks-text>
          de la formation, continue comme ça 💪
        </mks-text>
        <div class="progress">
          <div class="progress__done" :style="`width:${progress}%`"></div>
        </div>
      </div>
      <div class="section section--feedback">
        <mks-heading tag="h3" class="heading__wrapper"
          >Ton avis est précieux, <br>aide nous à améliorer la formation !</mks-heading
        >
        <mks-button tag="a" :href="typeform" target="blank"
          >Je donne mon avis</mks-button
        >
      </div>
      <div class="section section--links">
        <mks-heading tag="h3" class="heading__wrapper">Quelques liens utiles</mks-heading>
        <ul>
          <li>
            <mks-link
              v-if="program === 'sprint'"
              to="https://www.notion.so/MON-KIT-DE-MOBILISATEUR-RICE-44990b85385645a6a341f5aeff157429"
              >Mon kit de mobilisateur.ice</mks-link
            >
            <mks-link
              v-else
              to="https://www.notion.so/Kit-du-de-la-mobilisateur-rice-cfc1087c99e34e4a84a48cf4cf445fe3"
              >Mon kit de mobilisateur.ice</mks-link
            >
          </li>
          <li>
            <mks-link to="https://makesense.org/"
              >En savoir plus sur makesense</mks-link
            >
          </li>
          <li>
            <mks-link
              to="https://france.makesense.org/citoyens/s-engager/reaction/"
              >En savoir plus sur ré-action</mks-link
            >
          </li>
        </ul>
      </div>
    </div>
    <mks-loading type="component" v-else />
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "TraingingNext",
  data() {
    return {
      hookStatus: null,
    };
  },
  computed: {
    program() {
      return this.$route.params.program;
    },
    name() {
      return this.$route.query.name || "";
    },
    email() {
      return this.$route.query.email;
    },
    niveau() {
      return Number(this.$route.query.niveau);
    },
    progress() {
      return this.$route.query.progress;
    },
    typeform() {
      return `https://makesense.typeform.com/to/vlxUtcQt#email=${this.email}&module=${this.niveau}&program=${this.program}`;
    }
  },
  created() {
    // window.analytics.page("Checkin page");
    axios
      .get(
        `https://hooks.zapier.com/hooks/catch/6083165/oee2mqx/?mail=${this.email}&niveau=${this.niveau}`
      )
      .then((res) => {
        console.log(res);
        this.hookStatus = res.data.status;
      })
      .catch((err) => console.log(err));
  },
};
</script>
<style lang="scss" scoped>
.page__header {
  height:10rem;
  @include breakpoint('small') {
    height: 400px;
    min-height: 30vh;
    max-height: 45vh;
  }
}

.header__banner {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.page__content {
  margin: $space-m;
  background: $color-neutral-100;
  border-radius: $border-radius-rounded;
  position: relative;
  top: -2rem;
  padding: $space-l;
  text-align: center;
  width: 800px;
  max-width: 90%;
  margin: 0 auto;
  @include breakpoint('small') {
    top: -3rem;
  }
}

.section {
  margin-bottom: $space-xxl;
}

body .heading__wrapper{
  margin-bottom: $space-m;
}

.progress {
  background: $color-neutral-85;
  border-radius: $border-radius-rounded;
  height: $space-m;
  width: 80%;
  margin: $space-s auto 0;

  &__done {
    background: $color-success;
    height: 100%;
    border-radius: inherit;
  }
}

.fallback{
  background: $color-primary;
  height: 120px;
  width: 100%;
}
</style>