<template>
  <div class="main">
    <Loading class="loading" v-if="loadingState === 'PENDING'" />
    <div v-else class="events__list">
      <div class="event__now" v-if="featuredEvent.type">
        <div>
          <img
            v-if="featuredEvent.type === 'now'"
            src="@/assets/makesenseTV/brand/mks_now.png"
            class="now__logotype"
          />
          <img
            v-else
            src="@/assets/makesenseTV/brand/next.png"
            class="next__logotype"
          />
        </div>
        <div class="now__event" v-if="featuredEvent.event">
          <a target="_blank" :href="featuredEvent.event.url">
            <img class="now__event--cover" :src="featuredEvent.event.cover" />
          </a>
        </div>
      </div>
      <div
        class="list__group"
        v-for="(events, key) in getEvents"
        :key="key"
        :class="[
          {
            past: isPast(key),
          },
        ]"
      >
        <div
          class="group__date"
          :class="[
            {
              today: isToday(key),
            },
          ]"
        >
          <div class="date__day">
            <div class="day__number">{{ $date(key).format("D") }}</div>
          </div>
          <div class="date__fromnow">
            <div v-if="!isToday(key)">
              {{ day[lang][$date(key).day()] }} {{ $date(key).format("D") }}
              {{ month[lang][$date(key).month()] }}
            </div>
            <div v-else>
              <span v-if="lang === 'fr'">Aujourd'hui</span>
              <span v-else>Today</span>
            </div>
          </div>
        </div>
        <div class="group__events">
          <mks-event-line-item
            v-for="event in events"
            :key="event.id + 'test'"
            :link="event.url + '?utm_campaign=mkstv&utm_source=mkstv-iframe'"
            :title="event.title"
            :date="event.date"
            :cover="event.cover"
            :before-time-text="lang === 'fr' ? 'depuis' : 'since'"
            :duration="event.duration / 60"
            :metas="getMetas(event)"
          ></mks-event-line-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/atoms/Loading.vue";
import { functions as firebaseFunctions } from "../../modules/firebase";
import axios from "axios";
/* eslint-disable no-unused-vars */

export default {
  name: "MakesenseTvHome",
  components: {
    Loading,
  },
  computed: {
    getEvents: function() {
      var events = {};
      var count = 0;
      var next = 10000000;

      if (!this.events) return events;
      this.events.forEach((event) => {
        var date = this.$date(event.date).format("YYYY-MM-DD");

        var now = this.isNow(event.date);
        var since = this.startSince(event.date);

        if (this.when && this.when === "future" && this.isPast(date))
          return false;
        else if (this.when && this.when === "past" && !this.isPast(date))
          return false;

        if (events[date] === undefined) events[date] = [];

        var eventPerfect = {
          ...event,
          Location: "",
          date: event.date,
          end: event.endDate,
          duration:
            this.$date(event.endDate).unix() - this.$date(event.date).unix(),
          cover: event.cover[event.lang].url,
          title: event.title[event.lang],
          id: event._id,
          now: now,
          since: since,
          timestamp: this.$date(event.date).unix(),
        };

        if (
          since > 0 &&
          since < next &&
          (!this.featuredEvent.type || this.featuredEvent.type === "next")
        ) {
          this.featuredEvent = {
            type: "next",
            event: eventPerfect,
          };

          next = since;
        }

        events[date].push(eventPerfect);

        if (now)
          this.featuredEvent = {
            type: "now",
            event: eventPerfect,
          };

        count++;
      });

      return events;
    },
  },
  data: function() {
    return {
      events: null,
      when: "future",
      type: "all",
      lang: "fr",
      loadingState: "KO",
      featuredEvent: {
        type: null,
        event: null,
      },
      now: null,
      nextEvent: null,
      month: {
        fr: [
          "janvier",
          "février",
          "mars",
          "avril",
          "mai",
          "juin",
          "juillet",
          "août",
          "septembre",
          "octobre",
          "novembre",
          "décembre",
        ],
        en: [
          "january",
          "february",
          "march",
          "april",
          "may",
          "june",
          "july",
          "august",
          "september",
          "october",
          "november",
          "december",
        ],
      },
      day: {
        fr: [
          "Dimanche",
          "Lundi",
          "Mardi",
          "Mercredi",
          "Jeudi",
          "Vendredi",
          "Samedi",
        ],
        en: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
      },
    };
  },
  created() {
    this.$loadTheme("tv");
    if (this.$route.query.when !== undefined)
      this.when = this.$route.query.when;
    if (this.$route.query.type !== undefined)
      this.type = this.$route.query.type;
    if (this.$route.query.lang !== undefined)
      this.lang = this.$route.query.lang;

    this.get();
  },
  methods: {
    get: function() {
      this.loadingState = "PENDING";
      var events = firebaseFunctions.httpsCallable("mkstvEvents");

      let momentum = "5e6f5c1d673995064c72a5cf";
      if (this.type === "entrepreneurs") momentum = "5fc8bb1eadfd75058a000fc7";
      else if (this.type === "international")
        momentum = "6013cd94afb2e12cd4259386";

      return axios
        .get(
          `https://api.factory.makesense.org/v1/events?limit=100&page=0&momentum=${momentum}&status=active`
        )
        .then((res) => {
          if (
            res.status === 200 &&
            res.data.data !== undefined &&
            res.data.data.length > 0
          )
            this.events = res.data.data;
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.loadingState = "OK";
        });
      /*
      return events({
        type: this.type,
      })
        .then((events) => {
          this.events = events.data;
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          this.loadingState = "OK";
        });*/
    },
    isToday: function(date) {
      return date === this.$date(new Date()).format("YYYY-MM-DD");
    },
    isPast: function(date) {
      return (
        this.$date(date).unix() - this.$date(new Date()).unix() < 0 &&
        !this.isToday(date)
      );
    },
    isNow: function(date) {
      var seconds =
        this.$date(date).unix() + 3600 - this.$date(new Date()).unix();
      return seconds > 0 && seconds < 3600;
    },
    startSince: function(date) {
      var seconds = this.$date(date).unix() - this.$date(new Date()).unix();
      return parseInt(seconds / 60);
    },
    getMetas(event) {
      const metas = [];
      if (event.duration > 0) metas.push(this.getEventDuration(event.duration));
      if (event.type !== undefined) metas.push(event.type.name[event.lang]);
      if (event.visio !== undefined && event.visio) metas.push("Online");

      return metas;
    },
    getEventDuration(duration) {
      return duration / 60 >= 60
        ? ((duration / 60 / 60).toString().split(".").length > 1
            ? (duration / 60 / 60).toFixed(1)
            : duration / 60 / 60) +
            (this.lang === "fr" ? " heure" : " hour") +
            (duration / 60 / 60 >= 2 ? "s" : "")
        : (duration / 60).toFixed(0) + " minutes";
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700,800,900&display=swap");

$background-gray: #f8f8f8;
$text-color: #243b45;
$hightlight: #9c0951;
$yellow: #fef253;

html,
body {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main {
  position: relative;
  font-family: Raleway;
  margin-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 80px;

  &:after {
    content: "";
    display: block;
    position: fixed;
    bottom: 0px;
    left: 0px;
    height: 100px;
    right: 0px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
    pointer-events: none;
    z-index: 100;
  }
}

.events__list {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}

.list__group {
  margin-bottom: 60px;
}

.group__date {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.date__day {
  width: 50px;
  height: 50px;
  border-radius: 7px;
  overflow: hidden;
  padding-top: 13px;
  position: relative;

  box-shadow: 0px 0.69174px 1.38348px rgba(0, 0, 0, 0.1);
  background-color: $background-gray;
  line-height: 110%;
  font-family: Raleway;
  font-style: normal;
  font-weight: 900;
  font-size: 32.2812px;
  color: #243b45;
  transform: rotate(-7deg);

  margin-right: 15px;

  .day__number {
    height: 37px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -2px;
  }

  &:before {
    top: 0px;
    position: absolute;
    content: "";
    display: block;
    height: 13px;
    width: 100%;
    background-color: #f0986b;
    border-bottom: 0.322812px solid #979797;
  }
}

.date__fromnow {
  font-weight: bold;
  font-size: 22px;

  .today & {
    color: $hightlight;
    font-weight: 900;
  }

  &::first-letter {
    text-transform: uppercase;
  }
}

.event__now {
  margin-bottom: 60px;

  .now__logotype {
    width: 280px;
    margin-bottom: 20px;
  }

  .next__logotype {
    width: 160px;
    margin-bottom: 20px;
  }

  .now__event--cover {
    width: 100%;
    box-shadow: 0px 0px 20px #989898;
    border-radius: 10px;
  }
}
</style>
