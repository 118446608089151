<template>
  <main class="login">
    <form v-if="loading.emailForm !== 'OK'" class="email__form" @submit.prevent="onSendEmail">
      <div class="email__introduction">
        <Logotype></Logotype>
        <p>{{ content[getLang].introduction }}</p>
      </div>

      <div class="email__field-wrapper">
        <mks-field
          class="email__field"
          :placeholder="content[getLang].emailForm.emailPlaceholder"
          name="email"
          id="email"
          type="email"
          v-model="email"
          :required="true"
        />
      </div>
      <mks-button
        class="login__button"
        tag="button"
        type="positive"
        :loading="loading.emailForm === 'PENDING'"
      >{{ content[getLang].emailForm.findCampaigns }}</mks-button>
      <hr />
      <router-link :to="{ name: 'campaignCreate', query: { lang : getLang }}">
        <mks-button
          class="create__button"
          tag="button"
          type="neutral"
        >{{ content[getLang].createButton.label }}</mks-button>
      </router-link>
    </form>
    <div v-else>
      <p>{{ content[getLang].emailForm.success }}</p>
    </div>
  </main>
</template>
<script>
import Logotype from "./components/logotype.vue";
import lang from "../../mixins/lang.js";
import { functions as firebaseFunctions } from "@/modules/firebase";

export default {
  name: "ProgramsHome",
  components: { Logotype },
  mixins: [lang],
  data() {
    return {
      email: "",

      loading: {
        emailForm: null
      },
      content: {
        fr: {
          introduction:
            "Renseignez votre email ci-dessous et vous recevrez un mail avec toutes les campagnes que vous avez le droit de modifier.",
          emailForm: {
            emailPlaceholder: "Votre email de mobilisateur...",
            findCampaigns: "Retrouver mes campagnes",
            success:
              "Vous allez recevoir un email listant les campagnes que vous avez le droit d'éditer."
          },
          createButton: {
            label: "Créer une nouvelle campagne"
          }
        },
        en: {
          introduction:
            "Fill in your email below and you will receive an email with all the campaigns that you have the right to modify.",
          emailForm: {
            emailPlaceholder: "Your mobilizer email...",
            findCampaigns: "Recover my campaigns",
            success:
              "You will receive an email listing the campaigns that you have the right to edit."
          },
          createButton: {
            label: "New campaign"
          }
        }
      }
    };
  },
  created() {},
  computed: {
    lang() {
      return "fr";
    }
  },
  methods: {
    onSendEmail: function() {
      this.loading.emailForm = "PENDING";

      var getUserCampaigns = firebaseFunctions.httpsCallable(
        "programsGetCampaigns"
      );

      return getUserCampaigns({ email: this.email, locale: this.getLang })
        .then(response => {
          if (response.data.success) this.loading.emailForm = "OK";
          else this.loading.emailForm = "ERROR";
        })
        .catch(error => {
          throw error;
        });
    }
  },
  watch: {
    email() {}
  }
};
</script>
<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100vh;
  @include flex;
  align-items: center;
  justify-content: center;
  padding: $space-l;

  hr {
    border: none;
    border-top: 1px solid $border-color-base;
    margin: $space-base 0px;
  }

  form {
    width: 100%;
    max-width: 500px;

    .email__field-wrapper {
      margin-bottom: $space-m;
    }

    .login__button,
    .create__button {
      width: 100%;
    }
  }

  .email__introduction {
    margin-bottom: 2em;

    .logotype {
      font-size: 3em;
      margin-bottom: 0.4em;
      text-align: center;
    }

    p {
      @include text-body;
      text-align: center;
    }
  }
}
</style>
