import * as firebase from "firebase/app";
import "firebase/functions";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const config = {
  apiKey: "AIzaSyBGnWZ61PqCYcAWEGEu8qgn1IX3TCqEuJQ",
  authDomain: "makesense-quick.firebaseapp.com",
  databaseURL: "https://makesense-quick.firebaseio.com",
  projectId: "makesense-quick",
  storageBucket: "makesense-quick.appspot.com",
  messagingSenderId: "1076425957608",
  appId: "1:1076425957608:web:630285f616f6adea1fa95d",
};

var firebaseApp = firebase.initializeApp(config);
var functions = null;

if (process.env.NODE_ENV === "development") {
  functions = firebaseApp.functions();
  functions.useFunctionsEmulator("http://localhost:5000");
} else functions = firebaseApp.functions("europe-west1");

export default firebaseApp;
export { functions, firebaseApp, firebase };
