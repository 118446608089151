<template>
  <div class="timeline">
    <div class="line"></div>
    <div class="items">
      <div
        class="item"
        v-for="date in getGroupedOrder"
        :key="getGoupedItems[date].id"
        :class="{
          'item--style-primary': getGoupedItems[date].primary,
        }"
      >
        <div class="item__title">
          <mks-date-number class="item__date" v-if="dateMode">{{
            getGoupedItems[date].day
          }}</mks-date-number>
          <div v-else class="item__dot"></div>
          {{ getGoupedItems[date].formated }}
        </div>
        <div class="group">
          <div
            class="item__content"
            :key="item.id"
            v-for="item in getGoupedItems[date].items"
            v-on:click="onSelect(item.id)"
            :class="{
              'item__content--selected': selected && selected === item.id,
            }"
          >
            <div
              class="content"
              v-if="item.content !== undefined"
              v-html="item.content"
            ></div>
            <div class="content__meta">
              <mks-icon
                class="meta__icon"
                :type="item.icon"
                v-if="item.icon"
              ></mks-icon>
              <span v-if="item.meta !== undefined" v-html="item.meta"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Timeline",
  data: function() {
    return {
      selected: null,
    };
  },
  props: {
    items: {
      required: false,
      type: Array,
      default: function() {
        return [];
      },
    },
    dateMode: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onSelect: function(id) {
      this.selected = id;
      this.$emit("select", id);
    },
  },
  computed: {
    getGroupedOrder: function() {
      var order = [];

      this.items.forEach((item) => {
        if (!order.includes(item.date.full)) order.push(item.date.full);
      });

      return order;
    },
    getGoupedItems: function() {
      var grouped = {};

      this.items.forEach((item) => {
        if (grouped[item.date.full] === undefined)
          grouped[item.date.full] = {
            id: item.date.full,
            day: item.date.day,
            date: item.date.raw,
            formated: item.date.formated,
            primary: false,
            items: [],
          };

        grouped[item.date.full].items.push(item);

        if (item.style !== undefined && item.style === "primary")
          grouped[item.date.full].primary = true;
      });

      return grouped;
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  position: relative;
}

.items {
  position: relative;
  z-index: 1;
  margin-left: 10px;
}

.item {
  margin-bottom: $space-xl;

  &__title {
    padding-left: 35px;
    @include text-small;
    text-transform: capitalize;
  }

  &--style-primary {
    .item {
      &__title {
        @include text-body;
        @include text-body-black;
        text-transform: inherit;
      }
    }
  }

  &__dot,
  &__date {
    position: absolute !important;
    left: 0px;
  }

  &__dot {
    margin-left: 4px;
    width: 20px;
    height: 20px;
    background-color: #f1f1f1;
    border-radius: 1000px;
    margin-right: $space-xxs;
  }

  &__date {
    transform: scale(0.6) rotate(-7deg) !important;
    transform-origin: top left;
  }

  .group {
    position: relative;
  }

  &__content {
    position: relative;
    background: #ffffff;
    box-sizing: border-box;
    @include elevated;
    max-width: 500px;
    padding: $space-base;
    @include border-radius;
    margin-top: $space-base;
    margin-left: -12px;

    &:hover {
      cursor: pointer;

      .content {
        text-decoration: underline;
      }
    }

    &:before,
    &:after {
      content: " ";
      height: 0;
      pointer-events: none;
      position: absolute;
      width: 0;
      bottom: 100%;
      left: 24px;
      border: 10px solid transparent;
      border-bottom-color: #e8e8e8;
      margin-left: -10px;
    }

    &:after {
      border-bottom-color: #fff;
      border-width: 9px;
      margin-left: -9px;
    }

    &--selected {
      background: $color-neutral-95;
    }
  }

  .content {
    &__meta {
      color: $color-neutral-50;
      @include text-small;
      @include flex;

      .meta {
        &__icon {
          height: 16px;
          width: 16px;
          margin-right: $space-xxs;
        }
      }
    }
  }
}

.line {
  border: 2px dashed #f1f1f1;
  position: absolute;
  top: 0px;
  bottom: 0px;
  z-index: 0;
  left: 22px;
}
</style>
