<template> 
  <div class="main">
    <img src="https://s3-eu-west-1.amazonaws.com/makesense.org/uploads/20180320162752/ms_logo.png" class="logo">
    <div>Ta note est de :</div>
    <div class="note">
      {{this.$route.query.rating}}
    </div>
    <div class="retour">
      <p v-if="this.$route.query.rating == 5">
        Quelle super note ! Merci beaucoup ⭐️
      </p>
      <p v-if="this.$route.query.rating == 4">
        Merci beaucoup pour ton retour !
      </p>
      <p v-if="this.$route.query.rating == 3">
        C'est pas mal, mais on doit s'améliorer...
      </p>
      <p v-if="this.$route.query.rating == 2">
        Oula, pas ouf, il va falloir que tu nous en dises plus pour qu'on améliore les choses !
      </p>
      <p v-if="this.$route.query.rating == 1">
        Alors là, c'est bien dommage... Dis-nous en plus pour qu'on change les choses 😊
      </p>
    </div>
    <div>
      <p>Dis-nous en plus ici !</p>
      <a class="link" :href="$route.query.url + '/feedback/'">Donner mon avis</a>
    </div>
  </div>
</template>

<script>
import request from "request";

export default {
  name: "FeedbackThanks",
  computed: {},
  methods: {},
  mounted() {
    var jsonObject = {
      sourceId: "1KGRxrKUyv",
      userId: this.$route.query.userId,
      event: "Makesense Quick - New quick feedback",
      userEmail: this.$route.query.userEmail,
      properties: {
        rating: this.$route.query.rating
      }
    };

    var jsonData = JSON.stringify(jsonObject);

    let url = process.env.VUE_APP_API;

    if (process.env.NODE_ENV === "development") url += "/segmentGeneric";
    else url += "/segment";

    request.post(
      {
        headers: {
          "content-type": "application/json"
        },
        url: url,
        body: jsonData
      },

      function() {
        // error, response, body
      }
    );
  }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,500,600,700,800,900&display=swap");

.main {
  font-family: Raleway;
}
.note {
  font-size: 5vw;
  font-weight: bold;
}
.logo {
  width: 8%;
  margin-bottom: 5em;
}
.link {
  display: inline-block;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  cursor: pointer;
  padding: 10px 20px;
  border: 1px solid #018dc4;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  color: rgba(255,255,255,0.9);
  -o-text-overflow: clip;
  text-overflow: clip;
  background: #0199d9;
  -webkit-box-shadow: 3px 3px 4px 0 rgba(0,0,0,0.4);
  box-shadow: 3px 3px 4px 0 rgba(0,0,0,0.4);
  text-shadow: -1px -1px 0 rgba(15,73,168,0.66);
  font-weight: bold;
  text-decoration: none;
}
</style>
