var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"campaign"},[(_vm.loading !== 'OK')?_c('mks-loading'):_c('div',[_c('header',{staticClass:"campaign__header"},[_c('h1',[_vm._v("Tracking - "+_vm._s(_vm.campaign.name)+" - Promo "+_vm._s(_vm.currentPromo['PROMO']))]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.trackPeriod),expression:"trackPeriod"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.trackPeriod=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"all"}},[_vm._v("Tout")]),_c('option',{attrs:{"value":"0.5"}},[_vm._v("12 heures")]),_c('option',{attrs:{"value":"1"}},[_vm._v("1 jour")]),_c('option',{attrs:{"value":"3"}},[_vm._v("3 jours")]),_c('option',{attrs:{"value":"7"}},[_vm._v("7 jours")]),_c('option',{attrs:{"value":"15"}},[_vm._v("15 jours")]),_c('option',{attrs:{"value":"30"}},[_vm._v("30 jours")])])]),_c('main',{staticClass:"templates"},[_c('nav',{staticClass:"templates__menu"},_vm._l((_vm.campaign.promos),function(promo){return _c('div',{key:promo.id,staticClass:"menu__item",class:{
            '--active': promo.id === _vm.currentPromo.id
          },on:{"click":function($event){_vm.currentPromoId = promo.id}}},[_c('div',{staticClass:"item__name"},[_c('p',[_vm._v(_vm._s(promo['PROMO'])+" - "+_vm._s(_vm.$date(promo['Start date']).format('DD/MM/YYYY')))]),_c('p',[_vm._v(" "+_vm._s(promo.metrics.total)+" - "+_vm._s(Math.round(promo.metrics.delivered / promo.metrics.total * 100))+"% - "+_vm._s(Math.round(promo.metrics.opened / promo.metrics.total * 100))+"% - "+_vm._s(Math.round(promo.metrics.clicked / promo.metrics.total * 100))+"% ")])])])}),0),_c('div',{key:_vm.currentPromo.id + ':' + _vm.trackPeriod,staticClass:"templates__workspace"},_vm._l((_vm.currentPromo.mails),function(mail){return _c('div',{key:mail.id,staticClass:"template__fieldgroup",on:{"click":function($event){return _vm.toggle(mail.id)}}},[_c('div',{staticClass:"templates__edition"},[_c('div',[_c('h3',[_vm._v(_vm._s(mail.Name)+" - "+_vm._s(_vm.$date(mail.When).format('DD/MM/YYYY')))]),(mail.metrics)?_c('p',[_c('strong',[_vm._v("Destinataires")]),_vm._v(" : "+_vm._s(mail.Recipients)+", "),_c('strong',[_vm._v("Envois")]),_vm._v(": "+_vm._s(Math.round(mail.metrics.delivered / mail.Recipients * 100))+"%, "),_c('strong',[_vm._v("Ouverture")]),_vm._v(": "+_vm._s(Math.round(mail.metrics.opened / mail.Recipients * 100))+"%, "),_c('strong',[_vm._v("Click")]),_vm._v(": "+_vm._s(Math.round(mail.metrics.clicked / mail.Recipients * 100))+"% ")]):_vm._e()]),(_vm.expandedMails.includes(mail.id))?_c('ul',{on:{"!click":function($event){$event.stopPropagation();}}},_vm._l((mail.users),function(user){return _c('li',{key:user.mail,staticClass:"recipient",class:{
                '--danger': !user.delivered,
                '--warning': user.delivered && !user.opened,
                '--yellow': user.opened && !user.clicked,
                '--success': user.clicked
              }},[_vm._v(" "+_vm._s(user.mail)+" "+_vm._s(user.clicked ? 'Cliqué' : (user.opened ? 'Ouvert' : (user.delivered ? 'Envoyé' : '')))+" ")])}),0):_vm._e()])])}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }