<template>
  <main class="checkin">
    <div class="checkin__inner" v-if="event">
      <div class="event__image">
        <img class="event__image-inner" :src="event.cover[lang].url" />
      </div>
      <div class="checkin__content">
        <div class="event__infos">
          <mks-heading tag="h6" class="event__subtitle" style="shy"
            >{{ content[lang].subtitle }}
          </mks-heading>
          <mks-heading tag="h2" class="event__title">{{
            event.title[lang]
          }}</mks-heading>
          <p class="checkin__explanation">{{ content[lang].explanation }}</p>
          <mks-heading tag="h6">{{ content[lang].header }} </mks-heading>
          <countdown
            class="countdown"
            :time="(new Date() - $date(event.date)) * -1"
          >
            <template slot-scope="props">
              <mks-number v-if="props.days > 0" color="tertiary">
                <template v-slot:value>{{ props.days }}</template>
                <template v-slot:label>{{ content[lang].days }}</template>
              </mks-number>
              <mks-number v-if="props.hours > 0" color="tertiary">
                <template v-slot:value>{{ props.hours }}</template>
                <template v-slot:label>{{ content[lang].hours }}</template>
              </mks-number>
              <mks-number color="tertiary">
                <template v-slot:value>{{ props.minutes }}</template>
                <template v-slot:label>{{ content[lang].minutes }}</template>
              </mks-number>
              <mks-number color="tertiary">
                <template v-slot:value>{{ props.seconds }}</template>
                <template v-slot:label>{{ content[lang].seconds }}</template>
              </mks-number>
            </template>
          </countdown>
        </div>
        <form
          v-if="!checkedIn"
          class="checkin__form"
          :class="showSignUpForm ? 'checkin__form--extended' : ''"
          @submit.prevent="validateForm"
        >
          <div class="checkin__field-wrapper" v-if="!showSignUpForm">
            <mks-field
              class="checkin__field"
              :placeholder="content[lang].placeholder"
              name="email"
              id="email"
              type="email"
              v-model="email"
              :required="true"
            />
            <mks-field-error
              class="field__error"
              v-if="errorEmail"
              :error="content[lang].errors[errorEmail]"
            >
            </mks-field-error>
          </div>
          <mks-text v-else tag="p" weight="bold" color="light" size="small">{{email}}</mks-text>
          <div v-if="showSignUpForm" class="form__section">
            <mks-text class="section__title" tag="p" weight="bold">{{ content[lang].signUp }}</mks-text>
            <div class="checkin__field-wrapper">
              <mks-field
                class="checkin__field"
                :placeholder="content[lang].firstName.placeholder"
                :label="content[lang].firstName.label"
                name="firstName"
                id="firstName"
                type="text"
                v-model="user.firstName"
                :required="true"
              />
              <mks-field-error
                class="field__error"
                v-if="errorFirstName"
                :error="content[lang].errors[errorFirstName]"
              >
              </mks-field-error>
            </div>
            <div class="checkin__field-wrapper">
              <mks-field
                class="checkin__field"
                :placeholder="content[lang].lastName.placeholder"
                :label="content[lang].lastName.label"
                name="lastName"
                id="lastName"
                type="text"
                v-model="user.lastName"
                :required="true"
              />
              <mks-field-error
                class="field__error"
                v-if="errorLastName"
                :error="content[lang].errors[errorLastName]"
              >
              </mks-field-error>
            </div>
          </div>
          <mks-button
            class="checkin__submit"
            tag="button"
            :href="event.visioLink"
            :loading="loading"
            >{{ content[lang].checkinButton }}
            <template v-slot:loading>{{ content[lang].loading }}</template>
          </mks-button>
        </form>
        <div v-else-if="event.visio" class="checked-in">
          <p class="check-in__feedback">{{ content[lang].checkedIn }}</p>
          <mks-button
            tag="a"
            :href="event.visioLink"
            target="_blank"
            type="positive"
            size="full"
            >{{ content[lang].visioLink }}</mks-button
          >
          <div class="checkin__copy-wrapper">
            <div
              :class="{
                checkin__copy: true,
                'checkin__copy--done': copyFeedback === true,
              }"
              @click="copy"
            >
              {{ event.visioLink }} <span>📋</span>
            </div>
            <p class="checkin__copy-feedback" v-if="copyFeedback">
              {{ content[lang].copied }}
            </p>
          </div>
        </div>
        <div v-else>
          <p>{{ content[lang].checkedIn }}</p>
        </div>
      </div>
    </div>
    <div v-else>
      <mks-loading />
    </div>
  </main>
</template>
<script>
import axios from "axios";
import { functions as firebaseFunctions } from "@/modules/firebase";

export default {
  name: "CheckIn",
  data() {
    return {
      event: null,
      user: null,
      email: "",
      errorEmail: "",
      errorFirstName: "",
      errorLastName: "",
      reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/, //eslint-disable-line
      checkedIn: false,
      showSignUpForm: false,
      feedback: "",
      loading: false,
      copyFeedback: null,
      content: {
        fr: {
          checkedIn: "Super! Tu es bien enregistré.e!",
          checkinButton: "Check-in",
          copied: "Lien copié dans le presse-papier",
          days: "jours",
          errors: {
            empty: "Le champ doit être rempli",
            incorrect: "L'email n'est pas valide",
            maxReached:
            "Désolé, l'évènement est complet. Si tu es inscrit, vérifie que tu utilises la bonne addresse email !",
          },
          explanation:
            "Pour avoir accès au lien pour rejoindre l'évènement, tu dois t'enregistrer avec l'adresse e-mail fournie lors de ton inscription à l'évènement.",
          hours: "heures",
          loading: "Enregistrement...",
          minutes: "min",
          placeholder: "Entre ton email",
          seconds: "sec",
          subtitle: "Salle d'attente pour",
          visioLink: "Rejoindre l'évènement",
          header: "Débute dans ",
          signUp: "C'est ta première inscription! Tu t'appelles comment ?",
          firstName: {
            label: 'Prénom',
            placeholder: 'Julien'
          },
          lastName: {
            label: 'Nom',
            placeholder: 'Doré'
          }
        },
        en: {
          checkedIn: "Yay! You are checked-in.",
          checkinButton: "Check-in",
          copied: "Copied to clipboard",
          days: "days",
          errors: {
            empty: "The field can not be empty",
            incorrect: "The email is incorrect",
            maxReached:
            "Sorry, this event is full. If you're registered, make sure you are entering the right email address.",
          },
          explanation:
            "Check in with the email you used to register to the event, so you can get the link and join!",
          hours: "hours",
          loading: "Loading...",
          minutes: "min",
          placeholder: "Enter your email",
          seconds: "seconds",
          subtitle: "Checkin for",
          visioLink: "Go to conference",
          header: "Starts in ",
          signUp: "It's your first time ! What's your name ?",
          firstName: {
            label: 'First name',
            placeholder: 'Dolly'
          },
          lastName: {
            label: 'Last Name',
            placeholder: 'Parton'
          }
        },
        es: {
          checkedIn: "¡Excelente! Te has registrado bien",
          checkinButton: "Registrarse",
          copied: "Link copiado al portapapeles",
          days: "días",
          errors: {
            empty: "El campo debe ser llenado",
            incorrect: "El correo electrónico no es válido",
            maxReached:
            "Lo sentimos, el evento está agotado. Si te registraste, verifica que estés utilizando la dirección de correo electrónico correcta.",
          },
          explanation:
            "Para acceder al link para unirte al evento, debes usar la dirección de correo electrónico proporcionada al registrarte para este evento.",
          hours: "horas",
          loading: "Grabación...",
          minutes: "min",
          placeholder: "Introduce tu correo electrónico",
          seconds: "seg",
          subtitle: "Sala de espera para",
          visioLink: "Unirse al evento",
          header: "Comienza en ",
          signUp: "¡Este es tu primer registro! Cómo te llamas ?",
          firstName: {
            label: 'Nombre',
            placeholder: 'Danna'
          },
          lastName: {
            label: 'Apellido',
            placeholder: 'Paola'
          }
        },
      },
    };
  },
  created() {
    window.analytics.page("Checkin page");
    axios
      .get(`https://api.factory.makesense.org/v1/events/slug/${this.slug}`)
      .then((res) => {
        this.event = res.data;
      })
      .catch((err) => console.log(err));
  },
  computed: {
    lang() {
      return this.event.lang || "fr";
    },
    slug() {
      return decodeURIComponent(this.$route.query.e).replace(
        "https://events.makesense.org/e/",
        ""
      );
    },
    maxReached() {
      return this.event.attendees.length >= this.event.maxAttendees;
    } 
  },
  methods: {
    getSlugFromUrl() {},
    validateForm() {
      if (this.email == null || this.email == "") {
        this.errorEmail = "empty";
      } else if (!this.reg.test(this.email)) {
        this.errorEmail = "incorrect";
      } else if (!this.showSignUpForm){
        this.loading = true;
        this.getUser(this.email);
      } else {
        if (this.user.firstName === '') {
          this.errorFirstName = 'empty';
        }
        if (this.user.lastName === '') {
          this.errorLastName = 'empty';
        }
        this.loading = true;
        this.sendToApi();
      }
    },
    getUser(email) {
      var getUser = firebaseFunctions.httpsCallable("eventsGetUser");

      return getUser({ email })
        .then((response) => {
          if (!response.data)  {
            this.showSignUpForm = true;
            this.loading = false;
            this.user = {firstName: '', lastName: ''};
          }
          else {
            this.user = response.data;
            this.sendToApi();
            
          }
        });
    },
    sendToApi(email) {
      var checkin = firebaseFunctions.httpsCallable("eventsCheckin");

      return checkin({ event: this.event._id, user: { ...this.user, ...{email: this.email}}})
        .then((response) => {
          this.feedback = response.data.message;
          const uid = response.data.user;
          if (this.feedback === "MAX_REACHED") {
            this.errorEmail = "maxReached";

            window.analytics.track("Checkin error - Event already full", {
              event: this.event.title[this.lang],
              email: email,
            });
          } else if (this.feedback === "ADDED") {
            this.checkedIn = true;

            window.analytics.identify(uid, {
              email: email,
            });
            window.analytics.track("Checkin success", {
              event: this.event.title[this.lang],
              email: email,
            });
          }
          this.loading = false;
        })
        .catch((error) => {
          window.analytics.track("Checkin error", {
            event: this.event.title[this.lang],
            email: email,
            error,
          });
          this.loading = false;
          throw error;
        });
    },
    copy() {
      this.$copyText(this.event.visioLink).then(
        () => {
          this.copyFeedback = true;
          setTimeout(() => {
            this.copyFeedback = false;
          }, 3000);
        },
        () => {
          this.copyFeedback = false;
        }
      );
    },
  },
  watch: {
    email() {
      this.errorEmail = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.checkin {
  width: 90%;
  overflow: hidden;
  margin: $space-m auto;
  border-radius: $border-radius-m;
  background: $color-neutral-100;
  box-shadow: $box-shadow-l;

  @include breakpoint("small") {
    width: 80%;
    margin: $space-xxl auto;
  }

  // &:before {
  //   background: $color-roman;
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   //bottom: 0;
  //   height: 30vh;
  //   z-index: -1;
  // }

  .checkin__inner {
    @include breakpoint("medium") {
      display: flex;
    }
  }
  .event__image {
    flex: 0 0 50%;
    overflow: hidden;

    img {
      max-width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }
  .event__title {
    margin-bottom: $space-m;
  }
  .checkin__content {
    padding: $space-m;
  }
  .event__infos {
    margin-bottom: $space-m;
  }
  .checkin__explanation {
    margin-bottom: $space-m;
    color: $color-text-light;
  }

  .check-in__feedback {
    margin-bottom: $space-xs;
    @include text-large-black;
    text-align: center;
  }

  .checkin__copy {
    display: flex;
    align-items: center;
    margin-top: $space-m;

    border: 1px solid $border-color-base;
    background: $background-color-soft;
    border-radius: $border-radius-m;
    padding: $space-xs;
    cursor: pointer;
    @include text-small;
    color: $color-text-light;
    user-select: auto;

    span {
      margin-left: auto;
      @include text-large-black;
    }

    &--done {
      animation: toGreen 1s;
    }

    @keyframes toGreen {
      0% {
        background: $background-color-soft;
        //color: $color-text-light;
      }
      50% {
        background: $border-color-soft;
        //color: $text-color-success-inverse;
      }
      100% {
        background: $background-color-soft;
        //color: $color-text-light;
      }
    }
  }
  .checkin__copy-wrapper {
    position: relative;
    margin-bottom: $space-xl;
  }
  .checkin__copy-feedback {
    position: absolute;
    @include text-small;
    margin-top: $space-xxs;
    color: $color-text-light;
  }
  .countdown {
    display: flex;
    align-items: center;

    & > * {
      margin: 0 $space-xxs;
    }
  }
  .checkin__form {
    @include flex;
    justify-content: space-between;
    align-items: flex-start;

    &--extended {
      display: block;
      & > * {
        margin-bottom: $space-m;
      }
    }
  }

  .form__section{
    display: flex;
    flex-wrap: wrap;
  }
  .section__title{
    margin-bottom: $space-s;
  }

  .field__error {
    margin-top: $space-xxs;
  }

  .checkin__submit {
    flex-shrink: 0;
  }

  .checkin__field-wrapper {
    flex-grow: 1;
    margin-right: $space-xs;
  }
}
</style>
