<template>
  <div :class="['loading', 'loading--' + type]">
    <img
      class="loading__svg"
      src="@/assets/images/ui/loading.svg"
      :width="size"
    />
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    type: {
      type: String,
      default: "full",
      validator: function(value) {
        return (
          ["full", "component", "standalone", "simple"].indexOf(value) !== -1
        );
      }
    },
    progress: {
      type: Number,
      default: -1
    },
    size: {
      type: Number,
      default: 50
    },
    inline: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getProgress: function() {
      return Math.floor(this.progress);
    }
  }
};
</script>

<style lang="scss" scoped></style>
